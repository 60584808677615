// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CheckmarkIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CheckmarkIcon: NessieFunctionComponent<CheckmarkIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CheckmarkIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CheckmarkIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M8.42122 19.1223C8.98191 19.1643 9.55691 18.9709 9.98568 18.5422L20.4345 8.09329C21.2156 7.31224 21.2156 6.04591 20.4345 5.26486C19.6535 4.48382 18.3872 4.48381 17.6061 5.26486L8.42121 14.4498L5.44253 11.4711C4.66149 10.69 3.39516 10.69 2.61411 11.4711C1.83306 12.2521 1.83306 13.5185 2.61411 14.2995L6.85675 18.5422C7.28552 18.9709 7.86053 19.1643 8.42122 19.1223Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckmarkIcon.nessiePropTypes = iconNessiePropTypes;
CheckmarkIcon = nessiePropCheckerWrapper(CheckmarkIcon);
export default CheckmarkIcon;
