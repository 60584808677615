import { RAW_cssValue, ThemeUIStyleObject } from "../../stylingLib";
import React, { useState } from "react";
import { BodyText } from "../..";
import capitalize from "lodash/capitalize";
import logEvent from "../../../utils/logEvent";
import { handleTabChange } from "./helpers";

type TabProps = {
  id: string;
  label: string;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  dataName?: string;
};

const Tab: React.FC<TabProps> = ({ id, label, currentTab, setCurrentTab, dataName }) => {
  const handleSetCurrentTab = (id: string) => {
    setCurrentTab(id);
    if (dataName) {
      logEvent(`${dataName}.selected`);
    }
  };
  return (
    <div
      sx={tab}
      role="tab"
      tabIndex={currentTab === id ? 0 : -1}
      aria-selected={currentTab === id}
      aria-controls={`${id}Tabpanel`}
      id={id}
      data-name={dataName}
      onClick={() => handleSetCurrentTab(id)}
    >
      <BodyText>{capitalize(label)}</BodyText>
    </div>
  );
};

type TabbedSectionProps = {
  tabs: { id: string; label: string; content: React.ReactNode; dataName?: string }[];
  tabsStyle?: ThemeUIStyleObject;
  tabPanelStyle?: ThemeUIStyleObject;
};

const tab: ThemeUIStyleObject = {
  fontSize: "1.6rem",
  padding: "dt_s",
  fontWeight: "600",
  marginBottom: RAW_cssValue("-2px"),

  "&[aria-selected=true]": {
    borderBottom: "dt_active",
    backgroundColor: "dt_background_primary",
    backgroundSize: "100% 2px",
    backgroundRepeat: "no-repeat",
    span: {
      color: "dt_content_accent",
    },
  },
};

const tabsContainer: ThemeUIStyleObject = {
  display: "flex",
  position: "relative",
  borderBottom: "dt_disabled",
};

const TabbedSection: React.FC<TabbedSectionProps> = ({ tabs, tabsStyle, tabPanelStyle }) => {
  const [currentTab, setCurrentTab] = useState<string>(tabs[0]?.id || "");

  const renderTabs = () => (
    <div
      sx={{ ...tabsContainer, ...tabsStyle }}
      role="tablist"
      tabIndex={0}
      onKeyDown={(event) => handleTabChange(event, currentTab)}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          id={tab.id}
          label={tab.label}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          dataName={tab.dataName}
        />
      ))}
    </div>
  );

  return (
    <div>
      {renderTabs()}

      <div id={`${currentTab}Tabpanel`} role="tabpanel" aria-labelledby={currentTab} sx={tabPanelStyle}>
        {tabs.find((tab) => tab.id === currentTab)?.content || null}
      </div>
    </div>
  );
};

export default TabbedSection;
