import { getStringNameForLanguage } from "@classdojo/language";
import capitalize from "lodash/capitalize";
import { BodyText, MinutiaeText } from "../../../nessie";
import { GlobeIcon } from "../../../nessie/icons";
import { translate } from "../../../utils/translate";
import ProfilePicture from "../../misc/ProfilePicture";
import TooltipTrigger from "../../overlay/TooltipTrigger";
import { Receipt } from "./types";

type FilteredReceiptItemProps = {
  receipt: Receipt;
};

const FilteredReceiptItem = ({ receipt }: FilteredReceiptItemProps): JSX.Element => {
  return (
    <div
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        marginBottom: "dt_m",
        alignItems: "center",
        position: "relative",
      }}
    >
      <ProfilePicture src={receipt.entityAvatarURL} colorKey={receipt.entityId} size="4rem" />
      <div sx={{ display: "flex", flex: 1, flexDirection: "column", marginLeft: "dt_s", position: "relative" }}>
        <BodyText>{receipt.entityName}</BodyText>
        {receipt.entityRelation && <MinutiaeText kind="disabled">{receipt.entityRelation}</MinutiaeText>}
        {receipt.entityType === "teacher" && (
          <MinutiaeText kind="disabled">
            {capitalize(translate({ str: "dojo.common:post_receipts.teacher_caption" }))}
          </MinutiaeText>
        )}
      </div>
      {receipt.language && <ReceiptTranslation receipt={receipt} />}
    </div>
  );
};

export default FilteredReceiptItem;

type ReceiptTranslationProps = Pick<FilteredReceiptItemProps, "receipt">;

const ReceiptTranslation = ({ receipt }: ReceiptTranslationProps): JSX.Element | null => {
  const stringName = getStringNameForLanguage(receipt.language);
  const language = stringName && translate(stringName);
  if (!language) return null;
  const contentText = translate("dojo.common:class_story.translations.viewed", { language });
  return (
    <div sx={{ marginRight: "dt_l", position: "relative" }}>
      <TooltipTrigger tooltipContent={contentText} tooltipPosition="left" tooltipOffset="50%">
        <GlobeIcon color="dt_content_tertiary" />
      </TooltipTrigger>
    </div>
  );
};
