// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronRightIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronRightIcon: NessieFunctionComponent<ChevronRightIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronRightIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronRightIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M17.8002 12.0156C17.8042 11.4986 17.609 10.9803 17.2145 10.5858L10.2849 3.65613C9.50382 2.87509 8.23749 2.87508 7.45644 3.65613C6.67539 4.43718 6.67539 5.70352 7.45644 6.48456L12.9873 12.0154L7.45643 17.5462C6.67538 18.3273 6.67538 19.5936 7.45643 20.3747C8.23748 21.1557 9.50381 21.1557 10.2849 20.3747L17.2145 13.445C17.6089 13.0506 17.8041 12.5325 17.8002 12.0156Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronRightIcon.nessiePropTypes = iconNessiePropTypes;
ChevronRightIcon = nessiePropCheckerWrapper(ChevronRightIcon);
export default ChevronRightIcon;
