import platform from "platform";
import * as React from "react";
import UnstyledButton from "./UnstyledButton";

// IE9 does not support unicode X.
const X_CHAR = platform.name === "IE" && /9/.test(platform.version || "") ? "x" : "✕";

export default function CloseButton(props: React.ComponentPropsWithoutRef<typeof UnstyledButton>): JSX.Element {
  return (
    <UnstyledButton {...props} sx={{ color: "dt_content_tertiary" }}>
      {X_CHAR}
    </UnstyledButton>
  );
}
