//
// Calculates where to put a tooltip/popover for their respective triggers
//

import { NessieSpaceSizes } from "../../nessie/components/designTokens";

// eslint-disable-next-line complexity
type CalculatePositionData = {
  position?: "top" | "bottom" | "left" | "right";
  gutter?: NessieSpaceSizes;
  width?: string;
  align?: "left" | "center" | "right";
  caret?: boolean;
  offset?: string;
  caretOffset?: string;
};

type CaretPosition = "none" | "top" | "bottom" | "left" | "right";

type PositionResult = {
  caret?: CaretPosition;
  positionerProps?: {
    attachPoint: string;
    targetAttachPoint: string;
    gutterTop?: NessieSpaceSizes;
    gutterBottom?: NessieSpaceSizes;
    gutterLeft?: NessieSpaceSizes;
    gutterRight?: NessieSpaceSizes;
    align: "left" | "center" | "right";
    width?: string;
    zIndex: number;
  };
};

const zIndexOverlay = 110;

// eslint-disable-next-line complexity
export default function Overlay({
  position,
  gutter,
  width,
  align,
  caret,
  offset,
  caretOffset,
}: CalculatePositionData): PositionResult {
  let positionerProps;
  let caretPosition: CaretPosition | undefined;

  offset = offset || "50%";
  caretOffset = caretOffset || "50%";

  if (position === "top") {
    caretPosition = caret ? "bottom" : "none";
    positionerProps = {
      attachPoint: `${caretOffset} bottom`,
      targetAttachPoint: `${offset} top`,
      gutterBottom: gutter != null ? gutter : "dt_xs",
      align: align || "center",
      width,
      zIndex: zIndexOverlay,
    };
  } else if (position === "bottom") {
    caretPosition = caret ? "top" : "none";
    positionerProps = {
      attachPoint: `${caretOffset} top`,
      targetAttachPoint: `${offset} bottom`,
      gutterTop: gutter != null ? gutter : "dt_xs",
      align: align || "center",
      width,
      zIndex: zIndexOverlay,
    };
  } else if (position === "left") {
    caretPosition = caret ? "right" : "none";
    positionerProps = {
      attachPoint: `right ${caretOffset}`,
      targetAttachPoint: `left ${offset}`,
      gutterRight: gutter != null ? gutter : "dt_s",
      align: align || "right",
      width,
      zIndex: zIndexOverlay,
    };
  } else if (position === "right") {
    caretPosition = caret ? "left" : "none";
    positionerProps = {
      attachPoint: `left ${caretOffset}`,
      targetAttachPoint: `right ${offset}`,
      gutterLeft: gutter != null ? gutter : "dt_s",
      align: align || "left",
      width,
      zIndex: zIndexOverlay,
    };
  }

  return { caret: caretPosition, positionerProps };
}
