// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CheckIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CheckIcon: NessieFunctionComponent<CheckIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CheckIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CheckIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0754 4.92462C15.1759 1.02513 8.86432 1.02513 4.92462 4.92462C1.02513 8.82412 1.02513 15.1357 4.92462 19.0754C8.82412 22.9749 15.1357 22.9749 19.0754 19.0754C22.9749 15.1357 22.9749 8.82412 19.0754 4.92462ZM16.8643 8.94472L11.196 16.3819C10.995 16.6231 10.7538 16.7437 10.4322 16.7839H10.392C10.1106 16.7839 9.82915 16.6633 9.62814 16.4221L7.21608 13.5678C7.05528 13.3668 6.97487 13.1256 6.97487 12.8442C7.01508 12.603 7.13568 12.3618 7.33668 12.1608C7.73869 11.8392 8.38191 11.8794 8.70352 12.2814L10.3518 14.2111L15.2965 7.77889C15.6181 7.37688 16.2613 7.29648 16.6633 7.57789C16.8643 7.73869 16.9849 7.9799 17.0251 8.22111C17.0653 8.46231 17.0251 8.74372 16.8643 8.94472Z"
          />
          <mask
            id="CheckIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.0754 4.92462C15.1759 1.02513 8.86432 1.02513 4.92462 4.92462C1.02513 8.82412 1.02513 15.1357 4.92462 19.0754C8.82412 22.9749 15.1357 22.9749 19.0754 19.0754C22.9749 15.1357 22.9749 8.82412 19.0754 4.92462ZM16.8643 8.94472L11.196 16.3819C10.995 16.6231 10.7538 16.7437 10.4322 16.7839H10.392C10.1106 16.7839 9.82915 16.6633 9.62814 16.4221L7.21608 13.5678C7.05528 13.3668 6.97487 13.1256 6.97487 12.8442C7.01508 12.603 7.13568 12.3618 7.33668 12.1608C7.73869 11.8392 8.38191 11.8794 8.70352 12.2814L10.3518 14.2111L15.2965 7.77889C15.6181 7.37688 16.2613 7.29648 16.6633 7.57789C16.8643 7.73869 16.9849 7.9799 17.0251 8.22111C17.0653 8.46231 17.0251 8.74372 16.8643 8.94472Z"
            />
          </mask>
          <g mask="url(#CheckIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckIcon.nessiePropTypes = iconNessiePropTypes;
CheckIcon = nessiePropCheckerWrapper(CheckIcon);
export default CheckIcon;
