export function fullName(student: { firstName?: string; lastName?: string } = {}): string {
  const { firstName, lastName } = student;
  if (!firstName && !lastName) return "";
  const fallback = [firstName || "", lastName || ""].join(" ").trim();
  return fallback;
}

export function lastInitial(obj?: { firstName?: string; lastName?: string }): string {
  if (!obj) return "";
  return [obj.firstName || "", (obj.lastName || "").slice(0, 1)].join(" ").trim();
}

export function titleLast(obj?: { title?: string; lastName?: string }): string {
  if (!obj) return "";
  const { title, lastName } = obj;
  if (!title && !lastName) return "";
  const fallback = [title || "", lastName || ""].join(" ").trim();

  return fallback;
}

export function capitalize(word: string | undefined): string {
  if (!word) return "";
  return word.length ? word[0].toUpperCase() + word.slice(1) : word;
}

export function splitFirstAndLast(fullName: string): [string, string] {
  const names = fullName.replace(/\s+/g, " ").split(" ");
  const firstName = capitalize(names[0]);
  const lastName = capitalize(names.slice(1).join(" "));
  return [firstName, lastName];
}

// wraps the name in parentheses only if it is present
export function parenthesize(name: string | undefined): string {
  return name ? `(${name})` : "";
}
