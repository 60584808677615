import { setLogEventFunction, type LogEventFunction } from "./utils/logEvent";
import { setTranslateFunction, type TranslateFunction } from "./utils/translate";

type Config = {
  translate: TranslateFunction;
  logEvent: LogEventFunction;
};

export default function init(config: Config): void {
  setTranslateFunction(config.translate);
  setLogEventFunction(config.logEvent);
}
