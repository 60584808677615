import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import ExternalLink from "./ExternalLink";

/*
  Regex:
    1) Any number of numbers, letters, dots, colons, and slashes.
    2) A dot
    3) A top level domain
    4) (optional) a dot or slash, then any number of characters
*/

const PREFIX = /[a-zA-Z0-9\:\/\.\-]+\./.source;
// TODO if a string with http, *always* make it a link
const TLD_LIST = [
  "com",
  "org",
  "k12",
  "edu",
  "net",
  "co",
  "io",
  "ly",
  "gl",
  "be",
  "gov",
  "mil",
  "jp",
  "ru",
  "ca",
  "fr",
  "it",
  "au",
  "uk",
  "de",
  "es",
  "tr",
  "kr",
  "tw",
  "cn",
  "br",
  "nl",
  "ie",
  "ro",
  "me",
  "eu",
];
const SUFFIX = /((\/|\.).*)?/.source;
const PUNCTUATION_REGEX = /([.,:?!]{1,3})$/; // when a link is unintentionally concatenated with a period or other punctuation

function isLink(word: string): boolean {
  if (/^https?:\/\/[a-zA-Z0-9]/.test(word)) return true;
  if (/^www\.[a-zA-Z0-9]/.test(word)) return true;
  // NOTE: REGEXES ARE STATEFUL - we need to reconstruct one every time.
  return new RegExp(`^${PREFIX}(${TLD_LIST.join("|")})${SUFFIX}$`, "ig").test(word);
}

function prefexWithHTTP(word: string): string {
  return /^https?:\/\//.test(word) ? word : `http://${word}`;
}

function extractEndingPunctuation(word: string): { link: string; punctuation: string } {
  const matches = word.match(PUNCTUATION_REGEX);
  return {
    link: word.replace(PUNCTUATION_REGEX, ""),
    punctuation: matches ? matches[0] : "",
  };
}

export type RichTextProps = { text: string } & {
  linkStyle?: ThemeUIStyleObject;
  className?: string;
};

const RichText = ({ linkStyle, className, ...props }: RichTextProps): JSX.Element => {
  const translated = props.text;
  if (!translated) return <span />;

  const allLinkStyles = { ...styles.link, ...linkStyle };
  const output: React.ReactNode[] = [];
  let linkIdx = 0;
  let brIdx = 0;

  translated.split(/\n|\r/).forEach(function (line) {
    line.split(/\s+/).forEach((word, i) => {
      if (isLink(word)) {
        const { link, punctuation } = extractEndingPunctuation(word);
        if (i !== 0) {
          output.push(" ");
        }
        output.push(
          <ExternalLink
            key={`link-${linkIdx++}`}
            href={prefexWithHTTP(link)}
            sx={allLinkStyles}
            data-name="rich_text_link"
          >
            {link}
          </ExternalLink>,
        );
        output.push(punctuation);
      } else {
        output.push(`${i !== 0 ? " " : ""}${word}`);
      }
    });
    output.push(<br key={`br-${brIdx++}`} />);
  });

  return (
    <span data-name="richText" className={className}>
      {output}
    </span>
  );
};

export default RichText;

const styles: Record<string, ThemeUIStyleObject> = {
  link: {
    color: "dt_content_accent",
    textDecoration: "underline",
  },
};
