import "../../polyfills/requestAnimationFrame";
import { memo } from "react";
import * as React from "react";
import { CommentInput } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import LoadingMojo from "../misc/LoadingMojo";
import Comment from "./Comment";

type CommentListProps = {
  myId: string;
  myAvatarURL?: string;
  canComment?: boolean;
  renderedComments?: Omit<React.ComponentPropsWithoutRef<typeof Comment>, "myId" | "delete">[] | null;
  createComment: (comment: string) => void;
  deleteComment?: React.ComponentPropsWithoutRef<typeof Comment>["delete"];
};

// eslint-disable-next-line react-refresh/only-export-components
const CommentList = ({
  myId,
  myAvatarURL,
  canComment,
  renderedComments,
  createComment,
  deleteComment,
}: CommentListProps): JSX.Element => {
  function onSubmit(comment: string) {
    const trimmedComment = comment.trim();
    if (trimmedComment === "") return;

    createComment(trimmedComment);
  }

  if (!renderedComments) {
    return (
      <div sx={styles.loadingWrapper}>
        <LoadingMojo inline={true} text={translate("dojo.common:class_story.loading_comments")} />
      </div>
    );
  }

  return (
    <div>
      {renderedComments.map((comment) => (
        <Comment key={comment._id} {...comment} myId={myId} delete={deleteComment} />
      ))}
      {canComment && (
        <CommentInput
          placeholder={translate("dojo.common:class_story.comments_input")}
          aria-label={translate("dojo.common:class_story.comments_input")}
          onSubmit={onSubmit}
          sx={{ padding: "s" }}
          avatarURL={myAvatarURL}
          colorKey={myId}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(CommentList);

const styles: Record<string, ThemeUIStyleObject> = {
  loadingWrapper: {
    borderTop: "dt_divider",
    textAlign: "center" as const,
    padding: "l",
    color: "dt_content_tertiary",
  },
};
