import { ddsColors } from "./ddsColors";
import { spaces, radius, breakpoints, shadows, fontFamily, fontWeights, zIndexes, stackingOrder } from "./designTokens";

import { nessieColors } from "./nessieColors";
/**
 * Debug Color Theme: With this we can identify all hardcoded colors in the codebase
 * Chosen color: PIG 60
 */

export const ddsLightContentColors = {
  dt_content_primary: ddsColors.pig_60,
  dt_content_secondary: ddsColors.pig_60,
  dt_content_tertiary: ddsColors.pig_60,
  dt_content_light: ddsColors.pig_60,
  dt_content_disabled: ddsColors.pig_60,
  dt_content_accent: ddsColors.pig_60,
  dt_content_success: ddsColors.pig_60,
  dt_content_warning: ddsColors.pig_60,
  dt_content_danger: ddsColors.pig_60,
  dt_content_plus: ddsColors.pig_60,
};

export const ddsLightBackgroundColors = {
  dt_background_primary: ddsColors.pig_60,
  dt_background_secondary: ddsColors.pig_60,
  dt_background_tertiary: ddsColors.pig_60,
  dt_background_disabled: ddsColors.pig_60,
  dt_background_media: ddsColors.pig_60,
  dt_background_skrim: ddsColors.pig_60,
  dt_background_accent: ddsColors.pig_60,
  dt_background_success: ddsColors.pig_60,
  dt_background_warning: ddsColors.pig_60,
  dt_background_danger: ddsColors.pig_60,
};

export const ddsLightBorderColors = {
  dt_border_functional: ddsColors.pig_60,
  dt_border_stylistic: ddsColors.pig_60,
  dt_border_divider: ddsColors.pig_60,
  dt_border_transparent: ddsColors.pig_60,
  dt_border_disabled: ddsColors.pig_60,
  dt_border_active: ddsColors.pig_60,
};

export const ddsLightBrandColors = {
  dt_brand_classDojo: ddsColors.pig_60,
  dt_brand_plus: ddsColors.pig_60,
  dt_brand_tutor: ddsColors.pig_60,
};

export const contentColorStyleToToken: Record<ColorStyles, DDSContentColorKinds> = {
  primary: "dt_content_primary",
  secondary: "dt_content_secondary",
  tertiary: "dt_content_tertiary",
  light: "dt_content_light",
  disabled: "dt_content_disabled",
  accent: "dt_content_accent",
  success: "dt_content_success",
  warning: "dt_content_warning",
  danger: "dt_content_danger",
  plus: "dt_content_plus",
} as const;

export type ColorStyles =
  | "primary"
  | "secondary"
  | "tertiary"
  | "light"
  | "disabled"
  | "accent"
  | "success"
  | "warning"
  | "plus"
  | "danger";

export type DDSContentColorKinds = keyof typeof ddsLightContentColors;

//
// Primary theme object required by our ThemeProvider
//
export const theme = {
  fontFamily,
  fontWeights,
  space: spaces,
  radii: radius,
  shadows,
  breakpoints,
  zIndexes,
  stackingOrder,
  colors: {
    // transparent: "transparent",
    // inherit: "inherit",
    // white: "#ffffff",
    // black: "#000000",
    ...nessieColors, // NOTE Puri: Delete this after the migration
    ...ddsLightContentColors,
    ...ddsLightBackgroundColors,
    ...ddsLightBorderColors,
    ...ddsLightBrandColors,
  },
  borders: {
    none: "none",
    unset: "unset",
    dt_card: `2px solid ${ddsLightBorderColors.dt_border_stylistic}`,
    dt_functional: `2px solid ${ddsLightBorderColors.dt_border_functional}`,
    dt_active: `2px solid ${ddsLightBorderColors.dt_border_active}`,
    dt_divider: `2px solid ${ddsLightBorderColors.dt_border_divider}`,
    dt_disabled: `2px solid ${ddsLightBorderColors.dt_border_disabled}`,
  },
};
