import * as ScrollArea from "@radix-ui/react-scroll-area";
import { ThemeUIStyleObject } from "../stylingLib";

type ScrollContainerType = {
  maxHeight: number;
  children: React.ReactNode;
  className?: string;
};

export function ScrollContainer({ maxHeight, children, className }: ScrollContainerType) {
  return (
    <ScrollArea.Root type="auto" sx={scrollRootStyles} className={className}>
      <ScrollArea.Viewport sx={{ ...scrollViewportStyles, maxHeight }}>{children}</ScrollArea.Viewport>
      <ScrollArea.Scrollbar sx={scrollbarStyles} orientation="vertical">
        <ScrollArea.Thumb className="ScrollAreaThumb" sx={thumbStyles} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}

const scrollRootStyles: ThemeUIStyleObject = {
  width: "100%",
  height: "100%",
  overflow: "hidden",
};
const scrollViewportStyles: ThemeUIStyleObject = {
  width: "100%",
  height: "100%",
  wordBreak: "break-word",
};

const scrollbarStyles: ThemeUIStyleObject = {
  display: "flex",
  // ensures no selection
  userSelect: "none",
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: "none",
  background: "transparent",
  transition: "background 160ms ease-out",
  padding: "xs",
  width: "23px",
};

const thumbStyles: ThemeUIStyleObject = {
  flex: 1,
  backgroundColor: "dt_content_tertiary",
  borderRadius: "dt_radius_s",
  position: "relative",
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
    height: "100%",
    minWidth: "44px",
    minHeight: "44px",
  },
};
