import { BadgeKinds, NumberBadge, NumberBadgeProps } from "../../nessie";
import { translate } from "../../utils/translate";

export type BehaviorCircleProps = Omit<NumberBadgeProps, "value"> & {
  behavior: {
    points: number | null;
    positive: boolean;
  };
  absent?: boolean;
  hideIfZero?: boolean;
};

const BehaviorCircle = ({ behavior, absent, kind, hideIfZero, ...rest }: BehaviorCircleProps) => {
  const { points, positive } = behavior;
  if (points === null || (hideIfZero && !points)) return null;
  let circleType: BadgeKinds;

  if (kind) {
    circleType = kind;
  } else if (absent) {
    circleType = "secondary";
  } else if (behavior.points === 0) {
    circleType = "warning";
  } else if (positive) {
    circleType = "success";
  } else {
    circleType = "danger";
  }

  const content = !positive && points > 0 ? points * -1 : points;
  const circleLabel =
    points === 1
      ? // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
        translate({ str: "dojo.common:common.point", subs: { count: content }, fallback: `${content} point` })
      : // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
        translate({ str: "dojo.common:common.points", subs: { count: content }, fallback: `${content} points` });

  return <NumberBadge kind={circleType} aria-label={circleLabel} {...rest} value={content} />;
};

export default BehaviorCircle;

// eslint-disable-next-line react-refresh/only-export-components
export function getPointsFromObject(
  object: { currentPoints?: number; positivePoints?: number; negativePoints?: number },
  bubbleType?: "combinedTotal" | "separateTotals" | "dontShowPoints",
) {
  if (bubbleType === "separateTotals") return object.positivePoints || 0;
  else if (bubbleType === "combinedTotal") return (object.positivePoints || 0) - Math.abs(object.negativePoints || 0);
  else if (bubbleType === "dontShowPoints") return null;
  else return object.negativePoints || 0;
}
