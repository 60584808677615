import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

interface TileProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

export const Tile = ({ ...props }: TileProps) => {
  return <div sx={{ ...styles.tile }} {...props} />;
};

const styles: Record<string, ThemeUIStyleObject> = {
  tile: {
    border: `dt_card`,
    boxShadow: "dt_shadow_shadezies_small",
    borderRadius: "dt_radius_s",
    backgroundColor: "dt_background_primary",
  },
};
