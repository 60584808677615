import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import * as React from "react";
import { ScrollContainer } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import Modal from "./Base";

const SCREEN_SIZE_THRESHOLD = 1000; // modal stops having extra top padding here.
const SMALL_SCREEN_MARGIN = 20;
const LARGE_SCREEN_MARGIN = 70;

type ScrollableModalProps = React.ComponentPropsWithoutRef<typeof Modal> & {
  fixedTop?: boolean;
  header?: React.ReactNode;
  headerHeight?: number;
  footer?: React.ReactNode;
  footerHeight?: number;
  children: React.ReactNode;
  showModalOverflow?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
};

const ScrollableModal = ({
  fixedTop,
  header,
  headerHeight,
  footer,
  footerHeight,
  children,
  showModalOverflow,
  "data-name": dataName,
  ...props
}: ScrollableModalProps): JSX.Element => {
  const [windowHeight, setWindowHeight] = useState(typeof window === "undefined" ? 600 : window.innerHeight);

  const onResize = React.useMemo(() => {
    return debounce(() => setWindowHeight(window.innerHeight), 100);
  }, []);

  useEffect(() => {
    // To recalculate the component's height any time the window is resized.
    window && window.addEventListener("resize", onResize);

    return () => {
      window && window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  let margin;
  if (fixedTop && windowHeight > SCREEN_SIZE_THRESHOLD) {
    margin = LARGE_SCREEN_MARGIN;
  } else if (fixedTop) {
    margin = Math.max(SMALL_SCREEN_MARGIN, LARGE_SCREEN_MARGIN - (SCREEN_SIZE_THRESHOLD - windowHeight) / 4);
  } else {
    margin = SMALL_SCREEN_MARGIN;
  }

  const allowedHeight = windowHeight - ((header && headerHeight) || 0) - ((footer && footerHeight) || 0) - 2 * margin;

  const finalStyles: ThemeUIStyleObject = fixedTop
    ? { top: margin, transform: "none", WebkitTransform: "none", msTransform: "none" }
    : {};

  if (showModalOverflow) {
    finalStyles.overflow = "visible";
  }

  return (
    <Modal data-name={dataName} {...props} sx={finalStyles}>
      {header}
      <ScrollContainer maxHeight={allowedHeight} sx={{ borderRadius: header ? "0" : "dt_radius_s" }}>
        {children}
      </ScrollContainer>
      {footer}
    </Modal>
  );
};

export default ScrollableModal;
