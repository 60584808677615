// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type FileIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let FileIcon: NessieFunctionComponent<FileIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "FileIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: FileIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6617 3.97779C10.1111 2.43564 7.60492 2.43908 6.05853 3.98547C4.50061 5.5434 4.51044 8.07231 6.08044 9.61807L12.0631 15.5084C13.034 16.4643 14.5942 16.4582 15.5577 15.4948C16.5265 14.526 16.5265 12.9553 15.5577 11.9865L11.6679 8.09668C11.2774 7.70616 10.6442 7.70616 10.2537 8.09668C9.86315 8.4872 9.86316 9.12037 10.2537 9.51089L14.1435 13.4007C14.3312 13.5884 14.3312 13.8928 14.1435 14.0806C13.9568 14.2673 13.6544 14.2684 13.4663 14.0832L7.48361 8.19289C6.70505 7.42635 6.70017 6.17226 7.47274 5.39969C8.2396 4.63283 9.4824 4.63112 10.2514 5.39588L17.9111 13.0137C19.2721 14.3673 19.2751 16.5689 17.9178 17.9262C16.5631 19.2808 14.3668 19.2808 13.0121 17.9262L7.50542 12.4195C7.1149 12.029 6.48173 12.029 6.09121 12.4195C5.70068 12.81 5.70068 13.4432 6.09121 13.8337L11.5979 19.3404C13.7336 21.4761 17.1963 21.4761 19.332 19.3404C21.4719 17.2005 21.4671 13.7296 19.3214 11.5956L11.6617 3.97779Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
FileIcon.nessiePropTypes = iconNessiePropTypes;
FileIcon = nessiePropCheckerWrapper(FileIcon);
export default FileIcon;
