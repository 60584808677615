import { translate } from "../../../utils/translate";
import StandardModal from "../../modals/Standard";

type DeleteStoryPostModalProps = {
  close: () => void;
  delete: () => void;
  forComment?: boolean;
  commenterName?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
};

const DeleteStoryPostModal = ({
  forComment,
  commenterName,
  close,
  delete: _delete,
  "data-name": dataName = "deleteStoryPostModal",
}: DeleteStoryPostModalProps): JSX.Element => {
  return (
    <StandardModal
      small={true}
      title={
        forComment
          ? translate("dojo.common:class_story.comments_delete_modal.title")
          : translate("dojo.common:class_story.delete_post")
      }
      onRequestHide={close}
      primaryButton={{
        text: forComment
          ? translate("dojo.common:class_story.comments_delete_modal.delete")
          : translate("dojo.common:class_story.delete_post"),
        props: {
          kind: "destructive" as const,
          onClick: () => {
            close();
            _delete();
          },
          "data-name": "confirmDeleteButton",
        },
      }}
      secondaryButton={{
        text: translate("dojo.common:actions.cancel"),
        props: {
          onClick: close,
          "data-name": "cancelDeleteButton",
        },
      }}
      contentStyle={{ minHeight: "5rem" }}
      data-name={dataName}
    >
      {!forComment
        ? translate("dojo.common:class_story.delete_post_confirmation")
        : commenterName
          ? translate("dojo.common:class_story.comments_delete_modal.body", { name: commenterName })
          : translate("dojo.common:class_story.comments_delete_modal.body_self")}
    </StandardModal>
  );
};

export default DeleteStoryPostModal;
