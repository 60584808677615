import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

const Alert = ({ type, "data-name": dataName, children, className }: AlertProps) => {
  return (
    <div
      sx={{ ...styles.common, ...styles[type] }}
      role={type === "danger" ? "alert" : "generic"}
      data-name={dataName}
      className={className}
    >
      {children}
    </div>
  );
};

export type AlertProps = {
  type: "danger" | "warning" | "success" | "info";
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  className?: string;
  children: React.ReactNode;
};

export default Alert;

const styles: Record<string, ThemeUIStyleObject> = {
  common: {
    padding: "dt_l",
    borderRadius: "dt_radius_s",
    textShadow: "rgba(255, 255, 255, .6) 0 1px",
    fontWeight: "bold",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  danger: {
    backgroundColor: "dt_background_danger",
    borderColor: "dt_content_danger",
    color: "dt_content_danger",
  },
  warning: {
    backgroundColor: "dt_background_warning",
    borderColor: "dt_content_warning",
    color: "dt_content_warning",
  },
  info: {
    backgroundColor: "dt_background_accent",
    borderColor: "dt_border_active",
    color: "dt_content_accent",
  },
  success: {
    backgroundColor: "dt_background_success",
    borderColor: "dt_content_success",
    color: "dt_content_success",
  },
};
