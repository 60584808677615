// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type SendIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let SendIcon: NessieFunctionComponent<SendIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "SendIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: SendIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 3.63349C4 2.23889 5.32167 1.78714 6.05627 2.08941L23.0024 10.7555C24.3325 11.3028 24.3325 12.6972 23.0024 13.2445L6.05627 21.9106C5.32171 22.2129 4 21.7607 4 20.3666L4.72616 14.9102C5.08924 13.319 5.37717 13.4238 6.16319 13.319L17.8293 12.3831C17.8293 12.3831 19.2494 12.0043 17.8293 11.617C16.4092 11.2297 6.16319 10.6811 6.16319 10.6811C5.36954 10.5752 5.08924 10.6811 4.72616 9.08988C4.72616 9.08988 4 5.02808 4 3.63349Z"
          />
          <mask
            id="SendIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={4}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 3.63349C4 2.23889 5.32167 1.78714 6.05627 2.08941L23.0024 10.7555C24.3325 11.3028 24.3325 12.6972 23.0024 13.2445L6.05627 21.9106C5.32171 22.2129 4 21.7607 4 20.3666L4.72616 14.9102C5.08924 13.319 5.37717 13.4238 6.16319 13.319L17.8293 12.3831C17.8293 12.3831 19.2494 12.0043 17.8293 11.617C16.4092 11.2297 6.16319 10.6811 6.16319 10.6811C5.36954 10.5752 5.08924 10.6811 4.72616 9.08988C4.72616 9.08988 4 5.02808 4 3.63349Z"
            />
          </mask>
          <g mask="url(#SendIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SendIcon.nessiePropTypes = iconNessiePropTypes;
SendIcon = nessiePropCheckerWrapper(SendIcon);
export default SendIcon;
