import * as React from "react";
import BlockIcon from "../../nessie/components/iconsV2/output/BlockIcon";
import { NessieIconSizes } from "../../nessie/components/propTypes";
import { NessieThemeColors } from "../../nessie/components/theme";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { handleActionKeyDown } from "../../utils/keyboard";
import { UnstyledButton } from "../buttons";
import FallbackProfileBackground from "../../assets/images/components/profile_picture/placeholder_avatar.svg";

// eslint-disable-next-line react-refresh/only-export-components
export const PROFILE_PICTURE_SIZES = [
  "3rem",
  "3.2rem",
  "3.6rem",
  "4rem",
  "4.4rem",
  "5rem",
  "6.4rem",
  "8.5rem",
  "11.6rem",
] as const;

export type ProfilePictureImageSize = (typeof PROFILE_PICTURE_SIZES)[number];
const imageSizeToPx: Record<ProfilePictureImageSize, number> = {
  "3rem": 30,
  "3.2rem": 32,
  "3.6rem": 36,
  "4rem": 40,
  "4.4rem": 44,
  "5rem": 50,
  "6.4rem": 64,
  "8.5rem": 85,
  "11.6rem": 116,
};

const AvatarFillPercentage = 0.8;
// eslint-disable-next-line react-refresh/only-export-components
export function getFallbackProfileBackgroundStyle(size: ProfilePictureImageSize): ThemeUIStyleObject {
  const px = Math.floor((imageSizeToPx[size] || imageSizeToPx["11.6rem"]) * AvatarFillPercentage);

  return {
    backgroundImage: `url("${FallbackProfileBackground}")`,
    backgroundSize: `${px}px ${px}px`,
    backgroundPosition: "bottom center",
  };
}

/**
 * Little widget for displaying profile pictures and handlers for interacting
 * in case of needing logic on click.
 */
export type ProfilePictureProps = {
  src?: string;
  size?: ProfilePictureImageSize;
  onClick?: React.ReactEventHandler;
  colorKey?: string;
  className?: string;
  relativePath?: boolean;
  overlayColor?: NessieThemeColors;
  overlayOpacity?: number | string;
  tabIndex?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  "aria-label"?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  isBlocked?: boolean;
  preventShrink?: boolean;
};

const ProfilePicture = ({
  src,
  size = "4.4rem",
  onClick,
  colorKey,
  relativePath,
  overlayColor,
  overlayOpacity,
  tabIndex,
  children,
  "aria-label": ariaLabel,
  "data-name": dataName = "profilePicture",
  className,
  isBlocked,
  preventShrink,
}: ProfilePictureProps): JSX.Element => {
  const _onKeyDown = handleActionKeyDown({ onAction: onClick });

  const isClickable = !!onClick;
  const ariaProps = isClickable
    ? {
        tabIndex: tabIndex || 0,
        role: "button",
        "aria-label": ariaLabel,
        onKeyDown: _onKeyDown,
      }
    : undefined;

  const hasCustomAvatar = !!src && !defaultAvatarRegex.test(src);
  const hasOverlay = overlayColor || overlayOpacity;
  const wrapperStyles = getStyles({ src, relativePath, size, colorKey, hasCustomAvatar, isClickable, preventShrink });
  const ButtonOrDiv = onClick ? UnstyledButton : "div";
  return (
    <ButtonOrDiv onClick={onClick} sx={wrapperStyles} {...ariaProps} data-name={dataName} className={className}>
      {hasCustomAvatar && <div sx={{ ...styles.fill, ...styles.border }} />}
      {hasOverlay && <ProfilePictureOverlay overlayColor={overlayColor} overlayOpacity={overlayOpacity} />}
      <div sx={styles.fill}>
        <div
          sx={{
            height: "100%",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <div sx={{ flex: "0 0 auto", alignSelf: "center" }}>
            {isBlocked ? (
              <div
                sx={{
                  backgroundColor: "dt_background_primary",
                  borderRadius: "dt_radius_round",
                  boxShadow: "dt_shadow_shadezies_small",
                  lineHeight: 0,
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                <BlockIcon
                  sx={{ verticalAlign: "text-top" }}
                  size={getBlockedIconSize(size)}
                  color="dt_content_danger"
                />
              </div>
            ) : null}
            <div sx={styles.contents}>{children}</div>
          </div>
        </div>
      </div>
    </ButtonOrDiv>
  );
};

export default ProfilePicture;

type ProfilePictureOverlayProps = Pick<ProfilePictureProps, "overlayColor" | "overlayOpacity">;

const ProfilePictureOverlay = ({ overlayColor, overlayOpacity }: ProfilePictureOverlayProps): JSX.Element => {
  const dynamicStyle = {
    backgroundColor: overlayColor || "dt_background_primary",
    opacity: overlayOpacity || 0.3,
  };
  return <div sx={{ ...styles.fill, ...dynamicStyle }} />;
};

const defaultAvatarRegex = /\/dojo_bootstrap\/teacher\-avatars\/big\//;

type getStylesProps = Pick<ProfilePictureProps, "src" | "relativePath" | "colorKey" | "preventShrink"> & {
  size: ProfilePictureImageSize;
  hasCustomAvatar?: boolean;
  isClickable?: boolean;
};

const getStyles = ({
  src,
  relativePath,
  size,
  colorKey,
  hasCustomAvatar,
  isClickable,
  preventShrink,
}: getStylesProps): ThemeUIStyleObject => {
  if (src) {
    src = cleanSrc(src, relativePath);
  }

  const dynamicStyle = {
    flexShrink: preventShrink ? 0 : undefined,
    width: size,
    height: size,
    backgroundImage: hasCustomAvatar ? `url("${src}")` : undefined,
    ...(!hasCustomAvatar && getFallbackProfileBackgroundStyle(size)),
    ...(colorKey &&
      !hasCustomAvatar && {
        backgroundColor: RAW_cssValue(`hsl(${parseInt(colorKey.slice(-4), 16) % 360}, 45%, 86%)`),
      }),
    ...(isClickable && { cursor: "pointer" }),
  };

  return { ...styles.base, ...dynamicStyle };
};

const styles: Record<string, ThemeUIStyleObject> = {
  base: {
    position: "relative" as const,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    borderRadius: "dt_radius_round",
    backgroundColor: "dt_background_tertiary",
  },
  contents: {
    display: "inline-block",
  },
  fill: {
    position: "absolute" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "dt_radius_round",
  },
  border: {
    border: RAW_cssValue("1px solid rgba(0, 0, 0, 0.05)"),
  },
};

// eslint-disable-next-line react-refresh/only-export-components
export function cleanSrc(src: string, relativePath?: boolean): string {
  // Fix to get us off of imgix, which costs a lot of money.
  src = src
    .replace("dojofp.imgix.net", "images.classdojo.com/filepicker")
    .replace("dojoprofilephotos.imgix.net", "images.classdojo.com/dojoprofilephotos");

  // Make sure all images have a prefix
  const userGenerated = !src.match(/[a-f0-9]{32}.png/);
  if (src[0] === "/" && src[1] !== "/" && !relativePath && userGenerated) {
    src = `https://images.classdojo.com${src}`;
  }

  return src;
}

const getBlockedIconSize = (size: ProfilePictureImageSize): NessieIconSizes => {
  switch (size) {
    case "3rem":
    case "3.2rem":
    case "3.6rem":
      return "xs";
    case "4rem":
    case "4.4rem":
    case "5rem":
      return "s";
    case "6.4rem":
    case "8.5rem":
      return "m";
    case "11.6rem":
      return "xl";
    default:
      assertUnreachable(size);
  }

  return "m";
};

function assertUnreachable(size: never) {
  throw new Error(`Should be unreachable: ${size}`);
}
