import * as React from "react";
import { NessieSpaceSizes } from "../../nessie/components/designTokens";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

/**
 *  Component for positioning an element with a convenient API.
 *  The name of this component is explictly 'absolute' since this component
 *  relies on being used inside a relative container in order to work, which is
 *  left to the user.
 *
 *  The API allow us to set an intersection point between the elemnt that is
 *  gonna be positioned and the container of this element.
 *  The possible values are: "left center right" for "x: positioning and
 *  "top middle bottom" for "y" positioning.
 *
 *  We call them attach points since we specify what section of the element to be
 *  positioned has to attach to what section of the container.
 *
 *  Example for rendering our element on the outter right side of the container we do
 *    attachPoint="left middle"
 *    targetAttachPoint="right middle"
 *
 *  Gutters: You can provide individual gutters. Is gonna be a number that will multipy
 *  our internal gutter size.
 *
 *  zIndex: Let you specify a zIndex for the absolute wrapper in order to allow consumers
 *  to use it for overlays.
 */
type AbsolutePositionerProps = {
  attachPoint?: string;
  targetAttachPoint?: string;
  width?: React.CSSProperties["width"];

  zIndex?: React.CSSProperties["zIndex"];
  align?: React.CSSProperties["textAlign"];

  gutterTop?: NessieSpaceSizes;
  gutterRight?: NessieSpaceSizes;
  gutterBottom?: NessieSpaceSizes;
  gutterLeft?: NessieSpaceSizes;

  children: React.ReactNode;
};

const AbsolutePositioner = ({
  attachPoint = "center top",
  targetAttachPoint = "center bottom",
  width,
  zIndex,
  align,
  gutterTop = "0",
  gutterRight = "0",
  gutterBottom = "0",
  gutterLeft = "0",
  children,
}: AbsolutePositionerProps): JSX.Element => {
  const [attachX, attachY] = attachPoint.split(" ");
  const [targetAttachX, targetAttachY] = targetAttachPoint.split(" ");

  const _styles: ThemeUIStyleObject = {
    width,
    position: "absolute",
  };

  const horizontalMap: Record<string, string> = {
    left: "0%",
    center: "50%",
    right: "100%",
  };
  const verticalMap: Record<string, string> = {
    top: "0%",
    middle: "50%",
    bottom: "100%",
  };

  // determine position and translation
  let translateX = `-${horizontalMap[attachX] || attachX}`;
  const translateY = `-${verticalMap[attachY] || attachY}`;
  _styles.left = horizontalMap[targetAttachX] || targetAttachX;
  _styles.top = verticalMap[targetAttachY] || targetAttachY;

  // oddity of absolute positioning: positioned child has same line-break position as container,
  // so positioned child with left: 100% will line-break immediately instead of expanding to
  // width of content. prevent that using right: 0 instead of left: 100%
  if (targetAttachX === "right") {
    delete _styles.left;
    _styles.right = 0;
    translateX = `calc(100% - ${horizontalMap[attachX] || attachX})`;
  }

  // apply translation styles
  _styles.transform = `translate(${translateX}, ${translateY})`;
  _styles.msTransform = `translate(${translateX}, ${translateY})`; // React 15+ expects lowercase ms
  _styles.WebkitTransform = `translate(${translateX}, ${translateY})`;

  _styles.paddingTop = gutterTop;
  _styles.paddingRight = gutterRight;
  _styles.paddingBottom = gutterBottom;
  _styles.paddingLeft = gutterLeft;

  if (zIndex) _styles.zIndex = zIndex;
  if (align) _styles.textAlign = align;

  return <div sx={_styles}>{children}</div>;
};

AbsolutePositioner.propTypes = {
  attachPoint: attachmentPropValidator,
  targetAttachPoint: attachmentPropValidator,
};

export default AbsolutePositioner;

function attachmentPropValidator(props: Record<string, unknown>, propName: string, componentName: unknown) {
  const prop = props[propName];
  const errorMessage = `${componentName} failed prop validation for prop ${propName}`;

  if (typeof prop !== "string") return new Error(`${errorMessage} Looks like is not a string value.`);

  if (prop.split(" ").length > 2) return new Error(`${errorMessage} More than two position values provided.`);

  const [propX, propY] = prop.split(" ");

  if (!propX || !propY) return new Error(`${errorMessage} Looks like only one position was indicated in the string.`);

  // TODO validate for regex /^(left)|(center)|(right)|([0-9][0-9]?%)/
  // if (!_(["left", "center", "right"]).includes(propX.trim())) return new Error(errorMessage + ` X position: ${propX} is not a valid one.`);
  // if (!_(["top", "middle", "bottom"]).includes(propY.trim())) return new Error(errorMessage + ` Y position: ${propY} is not a valid one.`);
}
