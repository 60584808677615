// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type MinusIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let MinusIcon: NessieFunctionComponent<MinusIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "MinusIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: MinusIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47154 2 2 6.47154 2 12C2 17.5285 6.47154 22 12 22C17.5285 22 22 17.5285 22 12C22 6.47154 17.5285 2 12 2ZM16.5122 13.1382H7.56911C6.9187 13.1382 6.39024 12.6098 6.39024 11.9593C6.39024 11.3089 6.9187 10.7805 7.56911 10.7805H16.5122C17.1626 10.7805 17.6911 11.3089 17.6911 11.9593C17.6504 12.6098 17.122 13.1382 16.5122 13.1382Z"
          />
          <mask
            id="MinusIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.47154 2 2 6.47154 2 12C2 17.5285 6.47154 22 12 22C17.5285 22 22 17.5285 22 12C22 6.47154 17.5285 2 12 2ZM16.5122 13.1382H7.56911C6.9187 13.1382 6.39024 12.6098 6.39024 11.9593C6.39024 11.3089 6.9187 10.7805 7.56911 10.7805H16.5122C17.1626 10.7805 17.6911 11.3089 17.6911 11.9593C17.6504 12.6098 17.122 13.1382 16.5122 13.1382Z"
            />
          </mask>
          <g mask="url(#MinusIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MinusIcon.nessiePropTypes = iconNessiePropTypes;
MinusIcon = nessiePropCheckerWrapper(MinusIcon);
export default MinusIcon;
