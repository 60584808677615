import uniqueId from "lodash/uniqueId";
import CheckboxRadioInput, { CheckboxRadioInputsType } from "./CheckboxRadioInput";
import CheckboxRadioText from "./CheckboxRadioText";
import { InputGroup, InputGroupProps } from "./InputGroup";

export function CheckboxRadioGroup({
  groupName,
  inline,
  groupType,
  inputs,
  className,
  ...inputGroupProps
}: InputGroupProps & {
  groupName: string;
  inline?: boolean;
  groupType: "checkbox" | "radio";
  inputs: CheckboxRadioInputsType[];
  className?: string;
}) {
  return (
    <InputGroup groupType={groupType} {...inputGroupProps} className={className}>
      <div sx={{ display: "flex", flexDirection: inline ? "row" : "column", gap: "s" }}>
        {inputs.map((inputProps, index) => {
          const inputId = inputProps.id || uniqueId("inputGroupItem");
          const inputDetailId = uniqueId("inputGroupItemDetail");
          return (
            <div
              key={index}
              sx={{
                display: "flex",
                alignItems: inputProps.detail ? "flex-start" : "center",
                gap: "s",
              }}
            >
              <CheckboxRadioInput
                groupType={groupType}
                groupName={groupName}
                inputProps={inputProps}
                disabled={inputProps.disabled}
                inputId={inputId}
                inputDetailId={inputDetailId}
              />
              <CheckboxRadioText
                labelText={inputProps.labelText}
                detailText={inputProps.detail}
                inputId={inputId}
                detailId={inputDetailId}
              />
            </div>
          );
        })}
      </div>
    </InputGroup>
  );
}
