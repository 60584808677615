import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";

export const HEADER_HEIGHT = "6.8rem";
export const HEADER_LATERAL_PADDING = "2rem";

const styles: Record<string, ThemeUIStyleObject> = {
  modal: {
    width: "72rem",
    maxWidth: "90%",
    position: "relative",

    // centering
    top: "50%",
    left: 0,
    right: 0,
    bottom: "auto",
    transform: "translate(0, -50%)",
    marginY: "0",
    marginX: "auto",
    padding: 0,

    border: "dt_card",
    background: "dt_background_primary",
    WebkitOverflowScrolling: "touch",
    borderRadius: "dt_radius_s",
    boxShadow: "dt_shadow_shadezies",
  },

  modalHeader: {
    position: "relative",
    height: HEADER_HEIGHT,
    borderBottom: "dt_card",
    paddingY: `0`,
    paddingX: `dt_l`,
    display: "flex",
    alignItems: "center",
  },

  modalHeaderTitle: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  headerRightSideElement: {
    margin: 0,
    padding: 0,
    lineHeight: HEADER_HEIGHT,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  content: {
    padding: "dt_l",
  },

  loadingContainer: {
    position: "relative",
    height: "20rem",
  },

  tooltipSpacer: {
    height: "3rem",
  },

  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: RAW_cssValue("rgba(66, 62, 93, 0.9)"),
  },
};

export default styles;
