import { TRANSPARENT_PNG_DATA_URL, useIconSrc } from "./iconSet";

export function useIconFromBehavior(
  args: { positive?: boolean; iconNumber: number; useSvg?: boolean } | undefined,
): string {
  const { positive, iconNumber, useSvg } = args || {};
  const map = positive ? "positive_behavior" : "negative_behavior";
  const number = (iconNumber && Math.abs(iconNumber).toString()) || "1";

  const icon = useIconSrc(map, number);
  const backup = useIconSrc(map, "1");

  if (useSvg) throw new Error("implement SVG useIconFromBehavior");

  return icon || backup || TRANSPARENT_PNG_DATA_URL;
}

export function useIconFromUrl(url: string): string {
  const [pn, number] = url.replace(".png", "").split("/").slice(-2);
  const map = pn === "n" ? "negative_behavior" : "positive_behavior";

  const icon = useIconSrc(map, number);
  const backup = useIconSrc(map, "1");

  return icon || backup || TRANSPARENT_PNG_DATA_URL;
}
