/* eslint-disable no-restricted-imports */
import type { css } from "@emotion/react";
/* eslint-disable camelcase */
import { SxProp, ThemeUIStyleObject } from "@theme-ui/core";
import { css as themeToCssConverter } from "@theme-ui/css";
import { theme } from "../components/theme";

// for usage with ClassName's `css` function

export { ClassNames, Global as GlobalCSS, keyframes } from "@emotion/react";
export { ThemeProvider } from "@theme-ui/core";
export { RAW_cssValue } from "./RawCssValue";
export { useThemeBreakpoint } from "./useThemeBreakpoint";

export type { CSSInterpolation, ThemeUIStyleObject, SxProp };

type CSSInterpolation = Parameters<typeof css>[1];

export function transformStyleObject(styleObj: ThemeUIStyleObject) {
  const cssObj = themeToCssConverter(styleObj)(theme);
  return cssObj;
}
