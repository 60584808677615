import { getStringNameForLanguage } from "@classdojo/language";
import * as React from "react";
import { GlobeIcon, CheckmarkIcon } from "../../../nessie/icons";
import { parenthesize } from "../../../utils/name";
import { translate } from "../../../utils/translate";
import ProfilePicture from "../../misc/ProfilePicture";
import StandardModal from "../../modals/Standard";
import TooltipTrigger from "../../overlay/TooltipTrigger";
import { Receipt } from "./types";

type ReceiptsModalProps = {
  title: React.ComponentPropsWithoutRef<typeof StandardModal>["title"];
  receipts?: Receipt[];
  close: React.ComponentPropsWithoutRef<typeof StandardModal>["onRequestHide"];
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
};

const ReceiptsModal = ({ receipts, close, title, "data-name": dataName }: ReceiptsModalProps): JSX.Element => {
  return (
    <StandardModal
      small={true}
      title={title}
      loading={!receipts}
      onRequestHide={close}
      contentStyle={{ padding: "dt_s" }}
      data-name={dataName}
    >
      {receipts ? receipts.map((receipt) => <ReceiptItem key={receipt.entityId} receipt={receipt} />) : null}
    </StandardModal>
  );
};

export default ReceiptsModal;

type ReceiptProps = {
  receipt: Receipt;
};

const ReceiptItem = ({ receipt }: ReceiptProps) => {
  const absent = !receipt.time;

  return (
    <div sx={{ padding: "dt_xs", display: "flex", alignItems: "center" }} data-name="receiptDetails">
      <ProfilePicture src={receipt.entityAvatarURL} colorKey={receipt.entityId} size="3rem" />
      <div sx={{ flexGrow: 1, marginLeft: "dt_xl", marginRight: "dt_s", opacity: absent ? 0.5 : undefined }}>
        <span>{receipt.entityName}</span>{" "}
        <span sx={{ color: "dt_content_tertiary" }}>{parenthesize(receipt.entityRelation)}</span>
      </div>
      {receipt.language ? (
        <div sx={{ marginRight: "dt_s" }}>
          <ReceiptTranslation language={receipt.language} />
        </div>
      ) : null}
      <CheckmarkIcon color={absent ? "dt_content_tertiary" : "dt_content_accent"} />
    </div>
  );
};

type ReceiptTranslationProps = {
  language: string;
};

const ReceiptTranslation = ({ language }: ReceiptTranslationProps) => {
  const stringName = getStringNameForLanguage(language);
  const translatedLanguage = stringName && translate(stringName);
  if (!translatedLanguage) return null;

  const contentText = translate("dojo.common:class_story.translations.viewed", { language: translatedLanguage });

  return (
    <TooltipTrigger
      tooltipContent={contentText}
      tooltipPosition="left"
      tooltipOffset="35%" // not naturally centered for some reason
      block
    >
      <GlobeIcon color="dt_content_accent" sx={{ display: "block" }} />
    </TooltipTrigger>
  );
};
