import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "../../nessie/icons";
import { UnstyledButton } from "../buttons";

type ChevronButtonProps = {
  direction: "left" | "right";
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  "aria-label": string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};

const ChevronButton = ({
  direction,
  className,
  onClick,
  "aria-label": ariaLabel,
  "data-name": dataName,
}: ChevronButtonProps): JSX.Element => {
  return (
    <UnstyledButton
      sx={{
        width: 50,
        height: 50,
        borderRadius: "dt_radius_m",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
      }}
      className={className}
      aria-label={ariaLabel}
      onClick={onClick}
      data-name={dataName}
    >
      {direction === "left" && <ChevronLeftIcon color="dt_content_light" />}
      {direction === "right" && <ChevronRightIcon color="dt_content_light" />}
    </UnstyledButton>
  );
};

export default ChevronButton;
