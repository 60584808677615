import * as React from "react";
import { Button, Space } from "../../nessie";
import { TranslatedString } from "../../pods/i18n/translate";
import { translate } from "../../utils/translate";

type CommentsDisabledTooltipProps = {
  context: "school" | "classOrStudentStory";
  viewerType: "teacher" | "parent" | "student";
  contactName?: string;
  enableComments: React.ComponentPropsWithoutRef<typeof Button>["onClick"];
  messagePoster?: () => void;
};

// eslint-disable-next-line react-refresh/only-export-components
const CommentsDisabledTooltip = ({
  context,
  viewerType,
  contactName,
  enableComments,
  messagePoster,
}: CommentsDisabledTooltipProps): JSX.Element => {
  let commentsDisabledHeader: TranslatedString | null = null;
  let commentsDisabledText: TranslatedString | null = null;
  let commentsDisabledCTAText: TranslatedString | null = null;
  let commentsDisabledAction;
  if (viewerType === "teacher") {
    if (context === "school") {
      if (enableComments) {
        commentsDisabledHeader = translate("dojo.common:class_story.school_comments_disabled_tooltip.title_can_change");
        commentsDisabledText = translate("dojo.common:class_story.school_comments_disabled_tooltip.body_can_change");
        commentsDisabledCTAText = translate("dojo.common:class_story.comments_disabled_tooltip.button");
        commentsDisabledAction = enableComments;
      } else {
        commentsDisabledText = translate("dojo.common:class_story.school_comments_disabled_tooltip.body", {
          schoolName: contactName || "",
        });
      }
    } else {
      if (enableComments) {
        commentsDisabledHeader = translate("dojo.common:class_story.comments_disabled_tooltip.title");
        commentsDisabledText = translate("dojo.common:class_story.comments_disabled_tooltip.body");
        commentsDisabledCTAText = translate("dojo.common:class_story.comments_disabled_tooltip.button");
        commentsDisabledAction = enableComments;
      } else {
        commentsDisabledHeader = translate("dojo.common:class_story.comments_disabled_tooltip.title_collaborator");
        commentsDisabledText = contactName
          ? translate("dojo.common:class_story.comments_disabled_tooltip.body_collaborator", {
              teacherName: contactName || "",
            })
          : null;
      }
    }
  } else if (viewerType === "parent") {
    if (context === "school") {
      commentsDisabledText = translate("dojo.common:class_story.school_comments_disabled_tooltip.body", {
        schoolName: contactName || "",
      });
    } else {
      // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
      commentsDisabledText = translate("dojo.common:class_story.comments_disabled");
      commentsDisabledCTAText = translate("dojo.common:class_story.message_teacher", {
        teacherName: contactName || "",
      });
      commentsDisabledAction = messagePoster;
    }
  }

  return (
    <div sx={{ display: "flex", flexDirection: "column" }}>
      <div
        sx={{
          color: "dt_content_accent",
          fontSize: "1.8rem",
          fontWeight: 600,
        }}
      >
        {commentsDisabledHeader}
      </div>
      <Space size="dt_s" />
      <div>{commentsDisabledText}</div>
      <Space size="dt_s" />
      {commentsDisabledCTAText && commentsDisabledAction && (
        <Button data-name="commentsDisabledCTA" onClick={commentsDisabledAction}>
          {commentsDisabledCTAText}
        </Button>
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(CommentsDisabledTooltip);
