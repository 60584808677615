import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { getPrettyFileSize, getFileDescription, getFileIconName } from "../../utils/file";
import { translate } from "../../utils/translate";
import FileTypeIcon from "./FileTypeIcon";

export type FileAttachmentProps = {
  path: string;
  filename?: string;
  size?: number;
  removeButton?: React.ReactNode;
  "aria-label"?: string;
  className?: string;
};

const FileAttachment = ({
  path,
  filename,
  size,
  removeButton,
  className,
  "aria-label": ariaLabel,
}: FileAttachmentProps): JSX.Element => {
  const title =
    filename ||
    translate({
      str: "dojo.common:class_story.file_attachment.uploaded_file",
      fallback: "Uploaded file",
    });
  const subtitle = [getFileDescription(filename), getPrettyFileSize(size)].filter(Boolean).join(" - ");
  const label = ariaLabel || `${title} - ${subtitle}`;
  return (
    <div sx={styles.container} className={className}>
      <a href={path} target="_blank" rel="noopener noreferrer" aria-label={label} data-name="file_attachment">
        <div sx={{ display: "flex", alignItems: "center" }}>
          {filename && (
            <div>
              <FileTypeIcon fileIconName={getFileIconName(filename)} sx={styles.fileIcon} />
            </div>
          )}
          <div sx={{ flex: 1, marginLeft: "dt_s", display: "flex", flexDirection: "column" }}>
            <div sx={styles.filename}>{title}</div>
            <div sx={{ ...styles.subtitle, marginTop: "dt_s" }}>{subtitle}</div>
          </div>
        </div>
      </a>
      {removeButton}
    </div>
  );
};

export default FileAttachment;

const styles: Record<string, ThemeUIStyleObject> = {
  container: {
    position: "relative",
    boxShadow: "dt_shadow_shadezies_small",
    borderRadius: "dt_radius_s",
    border: `dt_card`,
    overflow: "hidden",
    padding: "dt_m",
  },
  fileIcon: {
    float: "left",
  },
  filename: {
    fontSize: "1.6rem",
  },
  subtitle: {
    fontSize: "1.4rem",
    color: "dt_content_tertiary",
  },
};
