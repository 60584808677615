import { MouseEventHandler, ReactNode } from "react";
import { NessieThemeColors } from "../../nessie/components/theme";
import Tooltip from "./Tooltip";

// eslint-disable-next-line react-refresh/only-export-components
export const popoverMaxWidth = "30rem";

type PopoverProps = {
  textColor?: NessieThemeColors;
  textSize?: string | null;
  children?: ReactNode;
  caret?: "none" | "top" | "bottom" | "left" | "right";
  caretOffset?: string;
  caretSize?: string;
  showCaretBorder?: boolean;
  className?: string;
} & (
  | {
      onClick?: undefined;
      /**
       * The name will get used for automated product events.
       * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
       */
      "data-name"?: string;
    }
  | {
      onClick: MouseEventHandler;
      "data-name": string;
    }
);

const Popover = (props: PopoverProps): JSX.Element => {
  return (
    <Tooltip
      caretSize="1.3rem"
      textColor="dt_content_primary"
      backgroundColor="dt_background_primary"
      sx={{
        padding: "dt_m",
        maxWidth: popoverMaxWidth,
      }}
      {...props}
    />
  );
};

export default Popover;
