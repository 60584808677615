import { useCallback, useState } from "react";
import * as React from "react";
import { NessieThemeColors } from "../../nessie/components/theme";
import { NessieSpaceSizes } from "../../nessie/components/designTokens";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { handleActionKeyDown } from "../../utils/keyboard";
import { UnstyledButton } from "../buttons";
import WithClickOutside from "../misc/WithClickOutside";
import { AbsolutePositioner } from "../positioning";
import calculatePosition from "./CalculatePosition";
import Popover, { popoverMaxWidth as defaultPopoverMaxWidth } from "./Popover";

/**
 * Component for wrapping an element that should display our a popover
 * when clicked.
 */

type PopoverTriggerProps = {
  isOpen?: boolean;
  className?: string;
  onOpen?: React.ReactEventHandler;
  onClose?: (event: React.SyntheticEvent | Event) => void;
  ["aria-label"]?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]: string;
  children?: React.ReactNode;
} & PopoverAtPositionProps &
  Omit<JSX.IntrinsicElements["button"], "style" | "children" | "onMouseEnter" | "onMouseLeave" | "onClick">;

const PopoverTrigger = ({
  isOpen,
  onOpen,
  onClose,
  children,
  className,
  popoverPosition = "top",
  popoverCaret = true,
  popoverOffset,
  popoverCaretOffset,
  popoverGutter,
  popoverAlign,
  popoverCaretSize,
  popoverTextSize,
  popoverTextColor,
  popoverMaxWidth = defaultPopoverMaxWidth,
  popoverPadding,
  popoverContent,
  ["aria-label"]: ariaLabel,
  ["data-name"]: dataName,
}: PopoverTriggerProps): JSX.Element => {
  const [isOpenState, setIsOpenState] = useState(false);

  const open = useCallback(
    (e: React.SyntheticEvent) => {
      if (!isOpenState) {
        setIsOpenState(true);
        if (onOpen) {
          onOpen(e);
        }
      }
    },
    [isOpenState, onOpen],
  );

  const close = useCallback(
    (e: React.SyntheticEvent | Event) => {
      if (isOpenState) {
        setIsOpenState(false);
        if (onClose) {
          onClose(e);
        }
      }
    },
    [isOpenState, onClose],
  );

  const togglePopover = useCallback(
    (e: React.SyntheticEvent) => {
      if (isOpenState) close(e);
      else open(e);
    },
    [close, isOpenState, open],
  );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") close(e);
    handleActionKeyDown({ onAction: togglePopover, onCancel: close });
  };

  return (
    <WithClickOutside onClickOutside={close}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div sx={{ position: "relative" }} onKeyDown={onKeyDown}>
        {/* children need to be wrapped in a different div than the popover at position, otherwise it will mess up the focus styles of the container in safari */}
        <UnstyledButton
          sx={styles.wrapper}
          className={className}
          onClick={togglePopover}
          onKeyDown={onKeyDown}
          aria-label={ariaLabel}
          aria-haspopup={true}
          aria-expanded={isOpenState || isOpen ? true : false}
          data-name={dataName}
        >
          {children}
        </UnstyledButton>
        {(isOpenState || isOpen) && (
          <PopoverAtPosition
            popoverPosition={popoverPosition}
            popoverCaret={popoverCaret}
            popoverOffset={popoverOffset}
            popoverCaretOffset={popoverCaretOffset}
            popoverGutter={popoverGutter}
            popoverAlign={popoverAlign}
            popoverCaretSize={popoverCaretSize}
            popoverTextSize={popoverTextSize}
            popoverTextColor={popoverTextColor}
            popoverMaxWidth={popoverMaxWidth}
            popoverPadding={popoverPadding}
            popoverContent={popoverContent}
          />
        )}
      </div>
    </WithClickOutside>
  );
};

export default PopoverTrigger;

type PopoverAtPositionProps = {
  popoverPosition?: "top" | "bottom" | "left" | "right";
  popoverCaret?: boolean;
  popoverOffset?: string;
  popoverCaretOffset?: string;
  popoverGutter?: NessieSpaceSizes;
  popoverAlign?: "left" | "center" | "right";
  popoverCaretSize?: string;
  popoverTextSize?: string;
  popoverTextColor?: NessieThemeColors;
  popoverMaxWidth?: string;
  popoverPadding?: NessieSpaceSizes;
  popoverContent: React.ReactNode;
};

const PopoverAtPosition = ({
  popoverPosition,
  popoverCaret,
  popoverOffset,
  popoverCaretOffset,
  popoverGutter,
  popoverAlign,
  popoverCaretSize,
  popoverTextSize,
  popoverTextColor,
  popoverMaxWidth,
  popoverPadding,
  popoverContent,
}: PopoverAtPositionProps): JSX.Element => {
  const { caret, positionerProps } = calculatePosition({
    position: popoverPosition,
    caret: popoverCaret,
    offset: popoverOffset,
    caretOffset: popoverCaretOffset,
    gutter: popoverGutter,
    width: popoverMaxWidth,
    align: popoverAlign,
  });
  // need to do this so undefineds don't override default popover props.

  return (
    <AbsolutePositioner {...positionerProps}>
      <Popover
        caret={caret}
        caretOffset={popoverCaretOffset}
        caretSize={popoverCaretSize}
        textSize={popoverTextSize}
        textColor={popoverTextColor}
        sx={{ ...styles.popoverContentWrapper, maxWidth: popoverMaxWidth, padding: popoverPadding }}
      >
        {popoverContent}
      </Popover>
    </AbsolutePositioner>
  );
};

const styles: Record<string, ThemeUIStyleObject> = {
  wrapper: {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
  },
  popoverContentWrapper: {
    cursor: "default",
  },
};
