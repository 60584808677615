// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type BlockIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let BlockIcon: NessieFunctionComponent<BlockIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "BlockIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: BlockIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 128 128"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.9998 117.333C93.455 117.333 117.333 93.4552 117.333 64C117.333 34.5448 93.455 10.6666 63.9998 10.6666C34.5447 10.6666 10.6665 34.5448 10.6665 64C10.6665 93.4552 34.5447 117.333 63.9998 117.333ZM63.9998 42.6666C67.9562 42.6666 71.6609 43.7436 74.837 45.6203L45.6202 74.8371C43.7435 71.661 42.6665 67.9563 42.6665 64C42.6665 52.2179 52.2178 42.6666 63.9998 42.6666ZM82.3795 53.1628L53.1627 82.3796C56.3387 84.2563 60.0435 85.3333 63.9998 85.3333C75.7819 85.3333 85.3332 75.782 85.3332 64C85.3332 60.0436 84.2562 56.3389 82.3795 53.1628ZM95.9998 64C95.9998 81.6731 81.673 96 63.9998 96C46.3267 96 31.9998 81.6731 31.9998 64C31.9998 46.3268 46.3267 32 63.9998 32C81.673 32 95.9998 46.3268 95.9998 64Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
BlockIcon.nessiePropTypes = iconNessiePropTypes;
BlockIcon = nessiePropCheckerWrapper(BlockIcon);
export default BlockIcon;
