import * as React from "react";
import { ModalCloseButton } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import LoadingMojo from "../misc/LoadingMojo";
import Footer from "../modals/Footer";

type WorkflowBoxProps = {
  header: React.ReactNode;
  washBg?: boolean;
  loading?: boolean;
  large?: boolean;
  onXClick?: () => void;
  contentStyle?: ThemeUIStyleObject;
  children?: React.ReactNode;
} & Pick<React.ComponentPropsWithoutRef<typeof Footer>, "primaryButton" | "secondaryButton">;

const WorkflowBox = ({
  header,
  washBg,
  loading,
  large,
  onXClick,
  contentStyle,
  children,
  primaryButton,
  secondaryButton,
}: WorkflowBoxProps): JSX.Element => {
  const contentStyles = {
    ...workflowBoxStyles.content,
    ...(washBg && workflowBoxStyles.washBg),
    ...contentStyle,
  };

  const containerStyles = {
    ...workflowBoxStyles.container,
    ...(large && workflowBoxStyles.large),
  };

  return (
    <div sx={containerStyles}>
      <header sx={workflowBoxStyles.header}>
        {header}
        {onXClick && <ModalCloseButton data-name="workflow_box:modal_close_button" onClick={onXClick} right />}
      </header>

      {children ? <div sx={contentStyles}>{loading ? <LoadingMojo /> : children}</div> : null}

      <div>
        {(primaryButton || secondaryButton) && !loading && (
          <Footer primaryButton={primaryButton} secondaryButton={secondaryButton} />
        )}
      </div>
    </div>
  );
};

export default WorkflowBox;

const rem = (unit: number) => `${unit}rem`;
const cssHeaderHeight = rem(6);
const workflowBoxStyles: Record<string, ThemeUIStyleObject> = {
  container: {
    borderRadius: "dt_radius_s",
    width: rem(55),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "dt_background_primary",
  },
  washBg: {
    backgroundColor: "dt_background_secondary",
  },
  header: {
    position: "relative",
    minHeight: cssHeaderHeight,
    borderBottom: "dt_divider",
    paddingX: "dt_l",
    paddingY: "0",
  },
  headerTitle: {
    margin: "0",
    padding: "0",
    fontSize: rem(1.8),
    fontWeight: "bold",
    lineHeight: cssHeaderHeight,
    position: "relative",
  },
  content: {
    position: "relative",
    padding: "dt_l",
    minHeight: rem(20),
  },
  large: {
    width: rem(64),
  },
};
