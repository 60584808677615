// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronUpIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronUpIcon: NessieFunctionComponent<ChevronUpIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronUpIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronUpIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12.0156 6.20001C11.4986 6.19601 10.9803 6.39125 10.5858 6.78574L3.65614 13.7154C2.87509 14.4964 2.87509 15.7628 3.65614 16.5438C4.43718 17.3249 5.70352 17.3249 6.48456 16.5438L12.0154 11.013L17.5462 16.5438C18.3273 17.3249 19.5936 17.3249 20.3747 16.5438C21.1557 15.7628 21.1557 14.4964 20.3747 13.7154L13.445 6.78574C13.0506 6.39134 12.5325 6.1961 12.0156 6.20001Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronUpIcon.nessiePropTypes = iconNessiePropTypes;
ChevronUpIcon = nessiePropCheckerWrapper(ChevronUpIcon);
export default ChevronUpIcon;
