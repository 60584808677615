import * as React from "react";
import { NessieThemeColors } from "../../nessie/components/theme";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import UnstyledButton from "../buttons/UnstyledButton";
import { AbsolutePositioner } from "../positioning";
import Triangle, { TriangleDirection } from "./Triangle";

/**
 * Simple Overlay element, no logic exposed, just a markup component.
 * Renders our basic version of Overlay elements which is basically a shadow
 * plus minor details.
 */

// eslint-disable-next-line react-refresh/only-export-components
export const tooltipMaxWidth = "25rem";

type TooltipProps = {
  backgroundColor?: NessieThemeColors;
  textColor?: NessieThemeColors;
  textSize?: string | null;
  children?: React.ReactNode;
  caret?: "none" | "top" | "bottom" | "left" | "right";
  caretOffset?: string;
  caretSize?: string;
  showCaretBorder?: boolean;
  className?: string;
} & (
  | {
      onClick?: undefined;
      /**
       * The name will get used for automated product events.
       * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
       */
      "data-name"?: string;
    }
  | {
      onClick: React.MouseEventHandler;
      /**
       * The name will get used for automated product events.
       * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
       */
      "data-name": string;
    }
);

const Tooltip = ({
  backgroundColor = "dt_content_secondary",
  textColor = "dt_content_light",
  textSize = "1.6rem",
  children,
  caret = "bottom",
  caretOffset = "50%",
  caretSize = "0.8rem",
  showCaretBorder = true,
  className,
  onClick,
  "data-name": dataName,
}: TooltipProps): JSX.Element => {
  const fontSize = textSize === null ? undefined : textSize;

  if (!dataName || !onClick) {
    return (
      <div
        sx={{ ..._styles.container, backgroundColor, color: textColor, fontSize }}
        className={className}
        role="status"
        aria-live="polite"
      >
        {caret !== "none" && (
          <TooltipDefaultCaret
            caret={caret}
            caretOffset={caretOffset}
            caretSize={caretSize}
            backgroundColor={backgroundColor}
            showCaretBorder={showCaretBorder}
          />
        )}
        {children}
      </div>
    );
  }

  return (
    <UnstyledButton
      onClick={onClick}
      data-name={dataName}
      sx={{ ..._styles.container, backgroundColor, color: textColor, fontSize }}
      className={className}
      role="status"
      aria-live="polite"
    >
      {caret !== "none" && (
        <TooltipDefaultCaret
          caret={caret}
          caretOffset={caretOffset}
          caretSize={caretSize}
          backgroundColor={backgroundColor}
          showCaretBorder={showCaretBorder}
        />
      )}
      {children}
    </UnstyledButton>
  );
};

export default Tooltip;

type TooltipDefaultCaretProps = Pick<TooltipProps, "caretOffset" | "caretSize" | "backgroundColor"> & {
  caret: "top" | "bottom" | "left" | "right";
  showCaretBorder: boolean;
};

const TooltipDefaultCaret = ({
  caret,
  caretOffset,
  caretSize,
  backgroundColor,
  showCaretBorder,
}: TooltipDefaultCaretProps): JSX.Element => {
  const color = backgroundColor || "dt_content_light";

  // init with default values for "bottom"
  let attachPoint = "center top";
  let targetAttachPoint = `${caretOffset} bottom`;
  let direction: TriangleDirection = "down";
  let triangleStyle: ThemeUIStyleObject = { marginTop: RAW_cssValue("-2px") };

  if (caret === "top") {
    attachPoint = "center bottom";
    targetAttachPoint = `${caretOffset} top`;
    direction = "up";
    triangleStyle = { marginBottom: RAW_cssValue("-1px") };
  } else if (caret === "left") {
    attachPoint = "right middle";
    targetAttachPoint = `left ${caretOffset}`;
    direction = "left";
    triangleStyle = { marginRight: RAW_cssValue("-2px") };
  } else if (caret === "right") {
    attachPoint = "left middle";
    targetAttachPoint = `right ${caretOffset}`;
    direction = "right";
    triangleStyle = { marginLeft: RAW_cssValue("-2px") };
  }

  return (
    <>
      {showCaretBorder === true && (
        <AbsolutePositioner attachPoint={attachPoint} targetAttachPoint={targetAttachPoint}>
          <Triangle direction={direction} color="dt_content_tertiary" size={caretSize} />
        </AbsolutePositioner>
      )}
      <AbsolutePositioner attachPoint={attachPoint} targetAttachPoint={targetAttachPoint}>
        <Triangle sx={triangleStyle} direction={direction} color={color} size={caretSize} />
      </AbsolutePositioner>
    </>
  );
};

const _styles: Record<string, ThemeUIStyleObject> = {
  container: {
    maxWidth: tooltipMaxWidth,
    display: "inline-block",
    position: "relative" as const,
    boxShadow: "dt_shadow_shadezies",
    backgroundColor: "dt_background_primary",
    borderRadius: "dt_radius_s",
    textAlign: "left",
    padding: "dt_s",
  },
};
