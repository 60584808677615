// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CameraVideoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CameraVideoIcon: NessieFunctionComponent<CameraVideoIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CameraVideoIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CameraVideoIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.88236 8.0354H13.1294C13.4824 8.0354 13.7647 8.35305 13.7647 8.70599V15.5883C13.7647 15.9413 13.4824 16.2236 13.1294 16.2236H3.88236C3.52942 16.2236 3.24707 15.9413 3.24707 15.5883V8.67069C3.24707 8.31775 3.52942 8.0354 3.88236 8.0354ZM19.9412 8.38834C20.1177 8.31775 20.2941 8.31775 20.4706 8.42364C20.6118 8.52952 20.7177 8.70599 20.7177 8.91775V15.4119C20.7177 15.5883 20.6471 15.7648 20.4706 15.8707C20.3647 15.9413 20.2588 15.9766 20.153 15.9766C20.0824 15.9766 20.0118 15.9766 19.9412 15.9413L15.2471 13.8589C15.0353 13.753 14.8941 13.5413 14.8941 13.3295V11.0001C14.8941 10.753 15.0353 10.5766 15.2471 10.4707L19.9412 8.38834Z"
          />
          <mask
            id="CameraVideoIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={3}
            y={8}
            width={18}
            height={9}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.88236 8.0354H13.1294C13.4824 8.0354 13.7647 8.35305 13.7647 8.70599V15.5883C13.7647 15.9413 13.4824 16.2236 13.1294 16.2236H3.88236C3.52942 16.2236 3.24707 15.9413 3.24707 15.5883V8.67069C3.24707 8.31775 3.52942 8.0354 3.88236 8.0354ZM19.9412 8.38834C20.1177 8.31775 20.2941 8.31775 20.4706 8.42364C20.6118 8.52952 20.7177 8.70599 20.7177 8.91775V15.4119C20.7177 15.5883 20.6471 15.7648 20.4706 15.8707C20.3647 15.9413 20.2588 15.9766 20.153 15.9766C20.0824 15.9766 20.0118 15.9766 19.9412 15.9413L15.2471 13.8589C15.0353 13.753 14.8941 13.5413 14.8941 13.3295V11.0001C14.8941 10.753 15.0353 10.5766 15.2471 10.4707L19.9412 8.38834Z"
            />
          </mask>
          <g mask="url(#CameraVideoIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CameraVideoIcon.nessiePropTypes = iconNessiePropTypes;
CameraVideoIcon = nessiePropCheckerWrapper(CameraVideoIcon);
export default CameraVideoIcon;
