// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type GlobeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let GlobeIcon: NessieFunctionComponent<GlobeIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "GlobeIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: GlobeIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.49393 2 2 6.49393 2 12C2 17.5061 6.49393 22 12 22C17.5061 22 22 17.5061 22 12C21.9595 6.49393 17.5061 2 12 2ZM12 20.583C9.48988 20.583 7.22267 19.4899 5.64372 17.7895C5.7247 17.587 6.12955 16.3725 6.41296 15.8057C6.73684 15.1984 6.81781 15.1174 7.50607 14.5101C8.19433 13.9028 6.12955 13.6194 5.64372 13.1336C5.15789 12.6478 4.91498 12.6478 4.38866 12.2024C3.98381 11.8785 3.74089 13.336 3.65992 14.0648C3.49798 13.417 3.417 12.7287 3.417 12.0405C3.417 10.2186 3.98381 8.51822 4.95547 7.1417C4.67206 7.9919 4.42915 9.20648 5.48178 8.8421C6.65587 8.43725 6.73684 7.95142 6.73684 7.95142L7.4251 6.73684C7.4251 6.73684 7.54656 8.39676 7.66802 8.63968C7.78947 8.88259 8.35628 9.57085 8.5587 8.59919C8.92308 6.81781 9.00405 6.33198 9.65182 6.33198C10.2996 6.33198 11.6356 5.80567 11.7976 7.38462C11.919 8.96356 11.6761 8.03239 10.7854 8.43725C9.93522 8.8421 9.73279 10.2996 10.502 10.4615C11.2713 10.6235 13.0931 11.3522 13.0931 11.3522L13.9838 12.3644L14.2672 13.417C14.2672 13.417 14.4291 14.6316 14.4291 14.7126C14.4291 14.834 14.4291 15.8057 14.4291 15.8057C14.4291 15.8057 14.4696 17.9514 15.2389 17.4656C16.0081 16.9393 18.1943 13.5789 17.749 12.3239C17.3036 11.0688 17.2632 10.9069 18.0729 10.4615C18.8826 10.0162 19.4899 9.44939 19.4494 8.8421C19.4089 8.39676 19.4494 8.15385 19.4899 7.87045C20.1377 9.08502 20.5425 10.502 20.5425 11.9595C20.5425 16.7368 16.6964 20.583 12 20.583Z"
          />
          <mask
            id="GlobeIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.49393 2 2 6.49393 2 12C2 17.5061 6.49393 22 12 22C17.5061 22 22 17.5061 22 12C21.9595 6.49393 17.5061 2 12 2ZM12 20.583C9.48988 20.583 7.22267 19.4899 5.64372 17.7895C5.7247 17.587 6.12955 16.3725 6.41296 15.8057C6.73684 15.1984 6.81781 15.1174 7.50607 14.5101C8.19433 13.9028 6.12955 13.6194 5.64372 13.1336C5.15789 12.6478 4.91498 12.6478 4.38866 12.2024C3.98381 11.8785 3.74089 13.336 3.65992 14.0648C3.49798 13.417 3.417 12.7287 3.417 12.0405C3.417 10.2186 3.98381 8.51822 4.95547 7.1417C4.67206 7.9919 4.42915 9.20648 5.48178 8.8421C6.65587 8.43725 6.73684 7.95142 6.73684 7.95142L7.4251 6.73684C7.4251 6.73684 7.54656 8.39676 7.66802 8.63968C7.78947 8.88259 8.35628 9.57085 8.5587 8.59919C8.92308 6.81781 9.00405 6.33198 9.65182 6.33198C10.2996 6.33198 11.6356 5.80567 11.7976 7.38462C11.919 8.96356 11.6761 8.03239 10.7854 8.43725C9.93522 8.8421 9.73279 10.2996 10.502 10.4615C11.2713 10.6235 13.0931 11.3522 13.0931 11.3522L13.9838 12.3644L14.2672 13.417C14.2672 13.417 14.4291 14.6316 14.4291 14.7126C14.4291 14.834 14.4291 15.8057 14.4291 15.8057C14.4291 15.8057 14.4696 17.9514 15.2389 17.4656C16.0081 16.9393 18.1943 13.5789 17.749 12.3239C17.3036 11.0688 17.2632 10.9069 18.0729 10.4615C18.8826 10.0162 19.4899 9.44939 19.4494 8.8421C19.4089 8.39676 19.4494 8.15385 19.4899 7.87045C20.1377 9.08502 20.5425 10.502 20.5425 11.9595C20.5425 16.7368 16.6964 20.583 12 20.583Z"
            />
          </mask>
          <g mask="url(#GlobeIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
GlobeIcon.nessiePropTypes = iconNessiePropTypes;
GlobeIcon = nessiePropCheckerWrapper(GlobeIcon);
export default GlobeIcon;
