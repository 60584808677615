import * as React from "react";
import { theme } from "./theme";
import { theme as ddsDarkTheme } from "./ddsDarkTheme";
import { theme as ddsLightTheme } from "./ddsLightTheme";
import { theme as ddsDebugTheme } from "./ddsDebugTheme";
import useOnFirstRender from "../../hooks/useOnFirstRender";
import { localStorage } from "@web-monorepo/safe-browser-storage";

export type Theme = typeof theme | typeof ddsDarkTheme | typeof ddsLightTheme | typeof ddsDebugTheme;

export const ColorThemeContext = React.createContext({ setNewTheme: (_newTheme: string) => {}, mode: "nessie" });

export const useColorTheme = () => {
  const [mode, setMode] = React.useState("dds-light");
  useOnFirstRender(() => {
    if (typeof window !== "undefined") {
      setMode(localStorage.getItem("theme") || "dds-light");
    }
  });

  const setNewTheme = (newMode: string) => {
    localStorage.setItem("theme", newMode);
    setMode(newMode);
  };

  // Update the theme only if the mode changes
  const selectedTheme: Theme = React.useMemo(() => {
    switch (mode) {
      case "nessie":
        return theme;
      case "dds-light":
        return ddsLightTheme;
      case "dds-dark":
        return ddsDarkTheme;
      case "dds-debug":
        return ddsDebugTheme;
      default:
        return ddsLightTheme;
    }
  }, [mode]);
  return { selectedTheme, mode, setNewTheme };
};
