import { ThemeUIStyleObject } from "../../nessie/stylingLib";

const STUDENT_USER_AVATAR_REGEX = /\d{3}-\d{3}-\d{3}-\d{3}-\d{3}\.png$|\/monsterWorldAvatar\//;

export type StudentAvatarProps = {
  src: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  className?: string;
  // should we check if the src url has a domain or prefix it with the `avatars.classdojo.com` domain if not
  checkSourceUrl?: boolean;
  faceCentered?: boolean;
};

/**
 * Student Avatar
 */
const StudentAvatar = ({
  src,
  checkSourceUrl = true,
  faceCentered = false,
  ...rest
}: StudentAvatarProps): JSX.Element => {
  if (checkSourceUrl && !/\/\//.test(src) && !/\.com|\.net/.test(src) && !/data:image\/png;base64/.test(src)) {
    src = `https://avatars.classdojo.com${src}`;
  }

  const avatarStyles = {
    backgroundImage: `url("${src}")`,
    ...defaultAvatarStyle,
    ...(STUDENT_USER_AVATAR_REGEX.test(src) && studentUserAvatarStyle), // Since these have weird padding on the sides
    ...(faceCentered && faceCenteredTransform),
  };

  const containerStyles = {
    ...defaultContainerStyle,
  };

  const imgStyles = {
    ...defaultImgStyle,
    ...(faceCentered && faceCenteredTransform),
  };

  return (
    <div {...rest} sx={containerStyles}>
      <div sx={avatarStyles} />
      <img alt="" src={src} sx={imgStyles} />
    </div>
  );
};

export default StudentAvatar;

const defaultContainerStyle: ThemeUIStyleObject = {
  height: "6rem",
  width: "6rem",
  display: "inline-block",
  position: "relative",
};
const defaultAvatarStyle: ThemeUIStyleObject = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center center",
  minHeight: "100%",
  minWidth: "100%",
};
const defaultImgStyle: ThemeUIStyleObject = {
  opacity: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

const faceCenteredTransform: ThemeUIStyleObject = {
  transform: "scale(1.2) translate(0px, 15%)",
};
const studentUserAvatarStyle: ThemeUIStyleObject = {
  backgroundSize: "cover",
};
