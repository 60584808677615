// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CommentIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CommentIcon: NessieFunctionComponent<CommentIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CommentIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CommentIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0488 3H8.91057C5.08943 3 2 6.37398 2 10.4797C2 14.2602 4.52033 17.3496 7.89431 17.878V21.2927C7.89431 21.6179 8.09756 21.9431 8.42276 22.065C8.50407 22.1057 8.62602 22.1057 8.74797 22.1057C8.95122 22.1057 9.19512 22.0244 9.35772 21.8618L13.0976 17.9593H15.0894C18.9106 17.9593 22 14.5854 22 10.4797C21.9593 6.37398 18.8699 3 15.0488 3ZM11.6341 13.7724H7.81301C7.56911 13.7724 7.4065 13.5691 7.4065 13.3659C7.4065 13.122 7.60976 12.9593 7.81301 12.9593H11.6341C11.878 12.9593 12.0407 13.1626 12.0407 13.3659C12.0407 13.6098 11.8374 13.7724 11.6341 13.7724ZM16.1463 11.0488H7.77236C7.52846 11.0488 7.36585 10.8455 7.36585 10.6423C7.36585 10.3984 7.56911 10.2358 7.77236 10.2358H16.1463C16.3902 10.2358 16.5528 10.439 16.5528 10.6423C16.5528 10.8455 16.3902 11.0488 16.1463 11.0488ZM16.1463 8.04065H7.77236C7.52846 8.04065 7.36585 7.8374 7.36585 7.63415C7.36585 7.39024 7.56911 7.22764 7.77236 7.22764H16.1463C16.3902 7.22764 16.5528 7.43089 16.5528 7.63415C16.5528 7.8374 16.3902 8.04065 16.1463 8.04065Z"
          />
          <mask
            id="CommentIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={3}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.0488 3H8.91057C5.08943 3 2 6.37398 2 10.4797C2 14.2602 4.52033 17.3496 7.89431 17.878V21.2927C7.89431 21.6179 8.09756 21.9431 8.42276 22.065C8.50407 22.1057 8.62602 22.1057 8.74797 22.1057C8.95122 22.1057 9.19512 22.0244 9.35772 21.8618L13.0976 17.9593H15.0894C18.9106 17.9593 22 14.5854 22 10.4797C21.9593 6.37398 18.8699 3 15.0488 3ZM11.6341 13.7724H7.81301C7.56911 13.7724 7.4065 13.5691 7.4065 13.3659C7.4065 13.122 7.60976 12.9593 7.81301 12.9593H11.6341C11.878 12.9593 12.0407 13.1626 12.0407 13.3659C12.0407 13.6098 11.8374 13.7724 11.6341 13.7724ZM16.1463 11.0488H7.77236C7.52846 11.0488 7.36585 10.8455 7.36585 10.6423C7.36585 10.3984 7.56911 10.2358 7.77236 10.2358H16.1463C16.3902 10.2358 16.5528 10.439 16.5528 10.6423C16.5528 10.8455 16.3902 11.0488 16.1463 11.0488ZM16.1463 8.04065H7.77236C7.52846 8.04065 7.36585 7.8374 7.36585 7.63415C7.36585 7.39024 7.56911 7.22764 7.77236 7.22764H16.1463C16.3902 7.22764 16.5528 7.43089 16.5528 7.63415C16.5528 7.8374 16.3902 8.04065 16.1463 8.04065Z"
            />
          </mask>
          <g mask="url(#CommentIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CommentIcon.nessiePropTypes = iconNessiePropTypes;
CommentIcon = nessiePropCheckerWrapper(CommentIcon);
export default CommentIcon;
