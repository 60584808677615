/*
 * ExternalLink is a link to a non-ClassDojo url that should be opened in a new
 * tab. It includes defense against the window.opener vulnerability.
 *
 * Previously we relied on blankshield for this, but we've since switched to a plain
 * <a> with rel="noopener noreferrer" now that browser support is better.
 */

type ExternalLinkProps = JSX.IntrinsicElements["a"] & {
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};

const ExternalLink = (props: ExternalLinkProps): JSX.Element => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

export default ExternalLink;
