import { NessieThemeColors } from "../../nessie/components/theme";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

export type TriangleDirection = "up" | "right" | "down" | "left";

/**
 * This is a triangle. You can specify its size, color, and direction.
 */
type TriangleProps = {
  direction?: TriangleDirection;
  size?: string;
  color?: NessieThemeColors;
  className?: string;
};

const Triangle = ({
  direction = "up",
  size = "1rem",
  color = "dt_content_primary",
  className,
}: TriangleProps): JSX.Element => {
  const position = {
    up: { borderBottomColor: color },
    down: { borderTopColor: color },
    left: { borderRightColor: color },
    right: { borderLeftColor: color },
  };
  return (
    <div
      sx={{
        ...triangleStyles,
        borderWidth: size,
        ...position[direction],
      }}
      className={className}
    />
  );
};

export default Triangle;

const triangleStyles: ThemeUIStyleObject = {
  width: "0",
  height: "0",
  borderStyle: "solid",
  borderColor: "transparent",
};
