// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type QuoteIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let QuoteIcon: NessieFunctionComponent<QuoteIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "QuoteIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: QuoteIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.22565 11.6113H6.58203C7.54935 11.6113 8.41908 11.9804 9.19124 12.7186C9.9634 13.4568 10.3495 14.3774 10.3495 15.4805C10.3495 16.5667 9.9634 17.4618 9.19124 18.1661C8.41908 18.8704 7.4645 19.2225 6.32747 19.2225C5.08862 19.2225 4.05768 18.7261 3.2346 17.7334C2.41153 16.7406 2 15.3278 2 13.495C2 11.8149 2.36062 10.2409 3.08187 8.77295C3.80312 7.30499 4.68557 6.14253 5.72926 5.28551C6.77295 4.4285 7.70208 4 8.51667 4C9.05973 4 9.48398 4.16122 9.78945 4.48366C10.0949 4.8061 10.2477 5.22187 10.2477 5.73099C10.2477 6.47769 9.91674 7.02923 9.25488 7.38561C8.35544 7.87776 7.64269 8.45475 7.1166 9.1166C6.59051 9.77845 6.29353 10.61 6.22565 11.6113ZM17.4771 11.6113H17.808C18.7584 11.6113 19.6196 11.9804 20.3918 12.7186C21.1639 13.4568 21.55 14.3774 21.55 15.4805C21.55 16.5497 21.1639 17.4491 20.3918 18.1788C19.6196 18.9086 18.665 19.2734 17.528 19.2734C16.2722 19.2734 15.237 18.7643 14.4224 17.7461C13.6078 16.7279 13.2005 15.3108 13.2005 13.495C13.2005 12.1883 13.4126 10.9579 13.8369 9.80391C14.2612 8.64991 14.8085 7.64442 15.4788 6.7874C16.1491 5.93039 16.8704 5.25158 17.6425 4.75094C18.4147 4.25031 19.1062 4 19.7172 4C20.2772 4 20.7057 4.15698 21.0027 4.47093C21.2997 4.78489 21.4482 5.2049 21.4482 5.73099C21.4482 6.49467 21.1173 7.0462 20.4554 7.38561C19.522 7.9117 18.8178 8.49293 18.3426 9.12933C17.8674 9.76573 17.5789 10.593 17.4771 11.6113Z"
          />
          <mask
            id="QuoteIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={4}
            width={20}
            height={16}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.22565 11.6113H6.58203C7.54935 11.6113 8.41908 11.9804 9.19124 12.7186C9.9634 13.4568 10.3495 14.3774 10.3495 15.4805C10.3495 16.5667 9.9634 17.4618 9.19124 18.1661C8.41908 18.8704 7.4645 19.2225 6.32747 19.2225C5.08862 19.2225 4.05768 18.7261 3.2346 17.7334C2.41153 16.7406 2 15.3278 2 13.495C2 11.8149 2.36062 10.2409 3.08187 8.77295C3.80312 7.30499 4.68557 6.14253 5.72926 5.28551C6.77295 4.4285 7.70208 4 8.51667 4C9.05973 4 9.48398 4.16122 9.78945 4.48366C10.0949 4.8061 10.2477 5.22187 10.2477 5.73099C10.2477 6.47769 9.91674 7.02923 9.25488 7.38561C8.35544 7.87776 7.64269 8.45475 7.1166 9.1166C6.59051 9.77845 6.29353 10.61 6.22565 11.6113ZM17.4771 11.6113H17.808C18.7584 11.6113 19.6196 11.9804 20.3918 12.7186C21.1639 13.4568 21.55 14.3774 21.55 15.4805C21.55 16.5497 21.1639 17.4491 20.3918 18.1788C19.6196 18.9086 18.665 19.2734 17.528 19.2734C16.2722 19.2734 15.237 18.7643 14.4224 17.7461C13.6078 16.7279 13.2005 15.3108 13.2005 13.495C13.2005 12.1883 13.4126 10.9579 13.8369 9.80391C14.2612 8.64991 14.8085 7.64442 15.4788 6.7874C16.1491 5.93039 16.8704 5.25158 17.6425 4.75094C18.4147 4.25031 19.1062 4 19.7172 4C20.2772 4 20.7057 4.15698 21.0027 4.47093C21.2997 4.78489 21.4482 5.2049 21.4482 5.73099C21.4482 6.49467 21.1173 7.0462 20.4554 7.38561C19.522 7.9117 18.8178 8.49293 18.3426 9.12933C17.8674 9.76573 17.5789 10.593 17.4771 11.6113Z"
            />
          </mask>
          <g mask="url(#QuoteIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
QuoteIcon.nessiePropTypes = iconNessiePropTypes;
QuoteIcon = nessiePropCheckerWrapper(QuoteIcon);
export default QuoteIcon;
