import BodyText from "../typography/bodyText";
import LabelText from "../typography/labelText";

const getDisabledTextStyles = (useDisabledStyles?: boolean, disabled?: boolean) => {
  if (useDisabledStyles && disabled) {
    return {
      color: "dt_content_disabled",
      cursor: "not-allowed",
    };
  }
  return {};
};

type CheckboxRadioTextProps = {
  labelText: string | React.ReactNode;
  inputId: string;
  detailText?: string | React.ReactNode;
  detailId?: string;
  limitSingleLine?: boolean;
  maxWidth?: string;
  useDisabledStyles?: boolean;
  disabled?: boolean;
};
/**
 * This component was created so it could be shared between web files that need a checkbox.
 * DO NOT USE IT OUTSIDE OF WEB AND NESSIE COMPONENTS
 */
function CheckboxRadioText({
  labelText,
  detailText,
  inputId,
  detailId,
  limitSingleLine,
  maxWidth,
  useDisabledStyles,
  disabled,
}: CheckboxRadioTextProps) {
  const getStyles = () => {
    const styles = {
      width: "fit-content",
      "a, a:visited": { color: "dt_content_accent" },
      cursor: "pointer",
      ...getDisabledTextStyles(useDisabledStyles, disabled),
    };
    return styles;
  };
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "xxs",
        flexGrow: 1,
        maxWidth,
        ...(useDisabledStyles && disabled ? { cursor: "not-allowed" } : {}),
      }}
    >
      {labelText ? (
        <LabelText level={2} as="label" htmlFor={inputId} textTransform="onlyFirst" sx={getStyles()}>
          {labelText}
        </LabelText>
      ) : null}
      {detailText ? (
        <BodyText
          level={2}
          id={detailId}
          kind="tertiary"
          sx={
            limitSingleLine
              ? {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  ...getDisabledTextStyles(useDisabledStyles, disabled),
                }
              : getDisabledTextStyles(useDisabledStyles, disabled)
          }
          textTransform="onlyFirst"
          aria-hidden={true}
        >
          {detailText}
        </BodyText>
      ) : null}
    </div>
  );
}

export default CheckboxRadioText;
