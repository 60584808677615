import { useImageSrc } from "./Image";

const FILE_TYPE_ICONS_METADATA = {
  file_doc: { width: "48px" },
  file_mp3: { width: "48px" },
  file_other: { width: "48px" },
  file_pdf: { width: "48px" },
  file_pdf_white: { width: "35px" },
  file_ppt: { width: "48px" },
  file_xls: { width: "48px" },
};

export type FileTypeIconNames = keyof typeof FILE_TYPE_ICONS_METADATA;
export const FileIconNames = Object.keys(FILE_TYPE_ICONS_METADATA);

type FileTypeIconMetadata = {
  src: string;
  width: string;
};

// eslint-disable-next-line react-refresh/only-export-components
export function useFileIcon(name: FileTypeIconNames): FileTypeIconMetadata {
  const src = useImageSrc(`components/icon/${name}.png`);

  return {
    src,
    width: FILE_TYPE_ICONS_METADATA[name].width,
  };
}

export type FileTypeIconProps = {
  fileIconName: FileTypeIconNames;
} & JSX.IntrinsicElements["img"];

const FileTypeIcon = ({ fileIconName, ...props }: FileTypeIconProps): JSX.Element => {
  const { src, width } = useFileIcon(fileIconName);

  return <img alt="" src={src} width={width} {...props} />;
};

export default FileTypeIcon;
