import { useState, memo } from "react";
import useSideEffectOnMount from "../../hooks/useSideEffectOnMount";
import { LabelText } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import { UploadType } from "../../utils/uppy/uploadConfigs";
import { UnstyledButton } from "../buttons";
import RichText from "../misc/RichText";
import T from "../misc/T";
import Attachments from "./PostAttachments";

export type Attachment = {
  type: UploadType;
  path: string;
  metadata?: { contentType?: string };
  contentType?: string;
  sources?: Array<{ path: string; contentType: string }>;
  transcoding?: boolean;
  thumbnailPath?: string;
};

export type PostContentsTextAndAttachmentProps = {
  attachments: Attachment[];
  hideDownloadLinkOnVideo?: boolean;
  visible?: boolean;
  logEvent: (eventName: string) => void;
  translationCount?: number;
  toggleReads?: () => void;
  doTranslate?: () => void;
  translation?: string | null;
  body?: string;
};

// eslint-disable-next-line react-refresh/only-export-components
const PostContentsTextAndAttachment = ({
  attachments,
  hideDownloadLinkOnVideo,
  visible,
  logEvent,
  translationCount,
  toggleReads,
  doTranslate,
  translation,
  body,
}: PostContentsTextAndAttachmentProps) => {
  const [showingTranslation, setShowingTranslation] = useState(false);
  const [canPlayWebm, setCanPlayWebm] = useState(true);

  useSideEffectOnMount(() => {
    const videoEl = document.createElement("video");
    setCanPlayWebm(videoEl.canPlayType('video/webm; codecs="vp8, vorbis"') != "");
  });

  const bodyText = showingTranslation && translation ? translation : body;

  const toggleTranslation = () => {
    if (!showingTranslation && !translation && doTranslate) {
      doTranslate();
    }
    setShowingTranslation(!showingTranslation);
  };

  const hasFileAttachment = attachments.some((attachment) => attachment.type === "file");

  return (
    <div data-name="storyPostContents">
      <div sx={{ paddingX: hasFileAttachment ? "dt_m" : "0" }}>
        <Attachments
          attachments={attachments}
          canPlayWebm={canPlayWebm}
          hideDownloadLinkOnVideo={hideDownloadLinkOnVideo}
          visible={visible}
          logEvent={logEvent}
        />
      </div>
      {bodyText && (
        <div sx={BODY_STYLE} translate="no" className="notranslate">
          <RichText text={bodyText} />
          <TranslationLink
            translationCount={translationCount}
            toggleReads={toggleReads}
            doTranslate={doTranslate}
            translation={translation}
            logEvent={logEvent}
            showingTranslation={showingTranslation}
            toggleTranslation={toggleTranslation}
          />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(PostContentsTextAndAttachment);

type TranslationLinkProps = Pick<
  PostContentsTextAndAttachmentProps,
  "translationCount" | "toggleReads" | "doTranslate" | "translation" | "logEvent"
> & { showingTranslation: boolean; toggleTranslation: () => void };

// eslint-disable-next-line react-refresh/only-export-components
const TranslationLink = ({
  translationCount = 0,
  toggleReads,
  doTranslate,
  translation,
  logEvent,
  showingTranslation,
  toggleTranslation,
}: TranslationLinkProps) => {
  let contents;
  // For the poster.
  if (toggleReads && translationCount > 0) {
    contents = (
      <UnstyledButton
        data-name="viewTranslationsButton"
        onClick={() => {
          logEvent("class_story.post_translations.open");
          toggleReads();
        }}
        sx={{ color: "dt_content_accent" }}
      >
        {translationCount === 1
          ? translate("dojo.common:class_story.translations.count", { count: "1" })
          : translate("dojo.common:class_story.translations.count_plural", { count: String(translationCount) })}
      </UnstyledButton>
    );
    // Loading state for parents.
  } else if (showingTranslation && !translation) {
    contents = (
      <LabelText data-name="translationsLoadingButton" kind="disabled" sx={{ fontSize: 14 }}>
        <T str="dojo.common:class_story.translations.fetching_translation" fallback="Translating..." />
      </LabelText>
    );
  } else if (doTranslate) {
    contents = showingTranslation ? (
      <UnstyledButton data-name={"showOriginalButton"} onClick={toggleTranslation} sx={{ color: "dt_content_accent" }}>
        <T str={"dojo.common:class_story.translations.view_original"} />
      </UnstyledButton>
    ) : (
      <UnstyledButton
        data-name={"showTranslationButton"}
        onClick={toggleTranslation}
        sx={{ color: "dt_content_accent" }}
      >
        <T str={"dojo.common:class_story.translations.to_my_language"} />
      </UnstyledButton>
    );
  }

  if (contents) {
    return <div sx={TRANSLATION_STYLE}>{contents}</div>;
  } else {
    return null;
  }
};
const BODY_STYLE: ThemeUIStyleObject = {
  margin: "dt_m",
  whiteSpace: "pre-line",
  wordWrap: "break-word",
  overflowWrap: "break-word",
};
const TRANSLATION_STYLE: ThemeUIStyleObject = {
  paddingTop: "dt_s",
};
