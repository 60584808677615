import { useEffect, useState, lazy, Suspense } from "react";
import * as React from "react";
import type { default as QrReaderType } from "react-qr-reader";
import { StandardModal, BaseModal, CloseButton, WorkflowBox, LoadingMojo } from "../..";
import { BodyText, Button, Space } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { isClient, isCypressTest } from "../../utils/env";
import { translate } from "../../utils/translate";
import { Image } from "../misc/Image";

declare global {
  interface Window {
    __mockQrScan?: (data: string | null) => void;
  }
}

const QrReader = isClient
  ? lazy(() => import("react-qr-reader"))
  : (_props: React.ComponentPropsWithoutRef<typeof QrReaderType>) => null;

type QrCodeLoginProps = {
  isStudentLogin: boolean;
  loggingIn: boolean;
  qrLoginFailed: boolean;
  closeQrModal?: () => void;
  onScan: (data: string) => void;
  onError: (err: unknown) => void;
  onlyReader?: boolean;
  onlyReaderStyle?: ThemeUIStyleObject;
};

const QrCodeLogin = ({
  isStudentLogin,
  loggingIn,
  qrLoginFailed,
  closeQrModal,
  onScan,
  onError,
  onlyReader,
  onlyReaderStyle,
}: QrCodeLoginProps): JSX.Element => {
  const [showingLoginError, setShowingLoginError] = useState(false);
  const [showingQrExplanation, setShowingQrExplanation] = useState(false);

  useEffect(() => {
    if (qrLoginFailed) {
      // just failed, show error
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setShowingLoginError(true);
    }
  }, [qrLoginFailed]);

  const hideLoginError = () => setShowingLoginError(false);

  const showExplanationModal = () => setShowingQrExplanation(true);

  const hideExplanationModal = () => setShowingQrExplanation(false);

  const handleScan = (data: string | null) => {
    if (data === null || loggingIn || showingLoginError) {
      return;
    }

    onScan(data);
  };

  const [titleStr, whatIsStr] = isStudentLogin
    ? [translate("dojo.common:student_code.scan"), translate("dojo.common:student_code.what_is")]
    : [translate("dojo.common:class_code.scan"), translate("dojo.common:class_code.what_is")];

  const whatIsLink = (
    <Button
      kind="tertiary"
      size="s"
      sx={MODAL_LINK_WITH_CLOSE_STYLE}
      onClick={showExplanationModal}
      data-name="qr_code_login:what_is"
    >
      {whatIsStr}
    </Button>
  );

  const headerRightSideElement = (
    <div sx={{ display: "flex", alignItems: "center", height: "100%" }}>
      {whatIsLink}
      {closeQrModal && <CloseButton data-name="qr_code_login:close_button" close={closeQrModal} />}
    </div>
  );

  const qrContent = (
    <div>
      <Suspense fallback={<LoadingMojo />}>
        <QrReader showViewFinder={false} onError={onError} onScan={handleScan} delay={1000} />
      </Suspense>
      <Image alt="" src="components/qr_reader/qrCodeOverlay.png" sx={QR_CODE_OVERLAY_STYLE} />
    </div>
  );

  if (isCypressTest) {
    window.__mockQrScan = handleScan;
  }

  const content = closeQrModal ? (
    <StandardModal
      data-name="qr_code_login:standard_modal"
      title={titleStr}
      headerRightSideElement={headerRightSideElement}
      onRequestHide={closeQrModal}
      width="500px"
      contentStyle={MODAL_CONTENT_STYLE}
    >
      {qrContent}
    </StandardModal>
  ) : onlyReader ? (
    <div
      sx={{
        ...MODAL_CONTENT_STYLE,
        ...ONLY_QR_READER_STYLE,
        ...onlyReaderStyle,
      }}
    >
      {qrContent}
    </div>
  ) : (
    <WorkflowBox
      header={
        <div sx={WORKFLOW_HEADER_STYLE}>
          {titleStr}
          <br />
          {whatIsLink}
        </div>
      }
      contentStyle={MODAL_CONTENT_STYLE}
    >
      {qrContent}
    </WorkflowBox>
  );

  return (
    <div>
      {content}
      {showingLoginError && <LoginErrorModal isStudentLogin={isStudentLogin} onClose={hideLoginError} />}
      {showingQrExplanation && <ExplanationModal isStudentLogin={isStudentLogin} onClose={hideExplanationModal} />}
    </div>
  );
};

export default QrCodeLogin;

type LoginErrorModalProps = {
  isStudentLogin: boolean;
  onClose: () => void;
};

const LoginErrorModal = ({ isStudentLogin, onClose }: LoginErrorModalProps) => {
  const [invalidCodeStr, tryAgainStr] = isStudentLogin
    ? [translate("dojo.common:student_code.invalid"), translate("dojo.common:student_code.try_again")]
    : [translate("dojo.common:class_code.invalid"), translate("dojo.common:class_code.try_again")];

  return (
    <BaseModal label={translate("dojo.common:error.login", "Login error")} data-name="qrCodeLogin">
      <div sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "dt_xxl" }}>
        <Image alt="" src="components/qr_reader/cone.png" width="100" height="100" />
        <Space size="dt_xl" />
        <BodyText as="h3">{invalidCodeStr}</BodyText>
        <Space size="dt_xl" />
        <Button onClick={onClose} size="s" data-name="login_error_modal:try_again">
          {tryAgainStr}
        </Button>
      </div>
    </BaseModal>
  );
};

type ExplanationModalProps = {
  isStudentLogin: boolean;
  onClose: () => void;
};

const ExplanationModal = ({ isStudentLogin, onClose }: ExplanationModalProps) => {
  let whatIsStr, looksLikeStr, howToGetStr, learnMoreStr;
  if (isStudentLogin) {
    whatIsStr = translate("dojo.common:student_code.what_is");
    looksLikeStr = translate("dojo.common:student_code.looks_like");
    howToGetStr = translate("dojo.common:student_code.how_to_get_code");
    learnMoreStr = translate("dojo.common:student_code.learn_more");
  } else {
    whatIsStr = translate("dojo.common:class_code.what_is");
    looksLikeStr = translate("dojo.common:class_code.looks_like");
    howToGetStr = translate("dojo.common:class_code.how_to_get_code");
    learnMoreStr = translate("dojo.common:class_code.learn_more");
  }
  return (
    <StandardModal
      data-name="qr_code_login:explanation_modal:standard_modal"
      title={whatIsStr}
      onRequestHide={onClose}
      width="500px"
      contentStyle={EXPLANATION_CONTENT_STYLE}
    >
      <div sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {looksLikeStr}
        <Space size="dt_xl" />
        <Image alt="" src="components/qr_reader/qrGraphic.png" />
        <Space size="dt_xl" />
        <div sx={{ textAlign: "center" }}>
          {howToGetStr}{" "}
          <a
            href="https://classdojo.zendesk.com/hc/articles/211840263"
            target="_blank"
            sx={{ fontWeight: "bold", color: "dt_content_accent" }}
            data-name={`qr_code_login:${isStudentLogin ? "student_code" : "class_code"}:learn_more`}
          >
            {learnMoreStr}
          </a>
        </div>
      </div>
    </StandardModal>
  );
};

const MODAL_LINK_WITH_CLOSE_STYLE: ThemeUIStyleObject = {
  margin: "auto",
  fontSize: "16px",
};

const MODAL_CONTENT_STYLE: ThemeUIStyleObject = {
  height: 550,
  position: "relative" as const,
  overflow: "hidden",
};

const QR_CODE_OVERLAY_STYLE: ThemeUIStyleObject = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) scaleX(-1)",
  height: "65%",
  opacity: "0.4",
};

const EXPLANATION_CONTENT_STYLE = {
  marginY: "dt_s",
  marginX: "dt_xl",
};

const WORKFLOW_HEADER_STYLE = {
  fontSize: "24px",
  fontWeight: "bold" as const,
  textAlign: "center" as const,
  paddingX: "dt_l",
  paddingY: "dt_m",
};

const ONLY_QR_READER_STYLE: ThemeUIStyleObject = {
  width: "55rem",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "dt_radius_s",
};
