import { useState } from "react";
import { LabelText } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { parenthesize } from "../../utils/name";
import { fromToday } from "../../utils/time";
import { translate } from "../../utils/translate";
import CloseButton from "../buttons/Close";
import LoadingMojo from "../misc/LoadingMojo";
import ProfilePicture from "../misc/ProfilePicture";
import RichText from "../misc/RichText";
import DeleteModal from "./modals/Delete";
import { parse } from "@web-monorepo/dates";

type CommentProps = {
  _id: string;
  myId: string;
  entityId: string;
  body: string;
  canDelete: boolean;
  entityName: string;
  entityRelation?: string;
  entityAvatarURL?: string;
  time?: string;
  delete?: (id: string) => void;
};

const Comment = ({
  _id,
  myId,
  entityId,
  entityName,
  entityAvatarURL,
  entityRelation,
  body,
  time,
  canDelete,
  delete: deleteFn,
}: CommentProps): JSX.Element => {
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);

  const canDeleteComment = !!deleteFn && canDelete;

  function deleteId() {
    if (deleteFn) deleteFn(_id);
  }

  function showDeleteModal() {
    if (canDeleteComment) setShowingDeleteModal(true);
  }

  function hideDeleteModal() {
    setShowingDeleteModal(false);
  }

  return (
    <div sx={styles.commentBox} data-name="commentItem">
      <ProfilePicture size="3.6rem" src={entityAvatarURL} colorKey={entityId} sx={{ flexShrink: "0" }} />
      <div sx={{ flexGrow: 1, marginLeft: "dt_l", display: "flex", flexWrap: "wrap", alignItems: "center", gap: "xs" }}>
        <LabelText sx={{ display: "inline" }}>{entityName}</LabelText>
        <span sx={{ color: "dt_content_tertiary" }}>{parenthesize(entityRelation)}</span>
        <RichText text={body} sx={{ width: "100%" }} />
      </div>
      <div data-name="commentTimeDeleteWrapper">
        {time ? (
          <span sx={styles.commentTimeDeleteContainer}>{fromToday(parse(time), "en")}</span>
        ) : (
          <LoadingMojo block sx={{ img: { width: "30px", height: "30px" } }} />
        )}
      </div>
      {canDeleteComment ? (
        <CloseButton
          data-name="comment:close_button"
          sx={styles.closeButton}
          onClick={showDeleteModal}
          aria-label={translate({
            str: "dojo.common:class_story.comments_delete_modal.delete",
            fallback: "Delete comment",
          })}
        />
      ) : null}

      {showingDeleteModal && (
        <DeleteModal
          forComment={true}
          // If name is not provided, modal will say "delete your comment"
          commenterName={myId === entityId ? undefined : entityName}
          delete={deleteId}
          close={hideDeleteModal}
          data-name="deleteCommentModal"
        />
      )}
    </div>
  );
};

export default Comment;

const styles: Record<string, ThemeUIStyleObject> = {
  commentBox: {
    position: "relative" as const,
    display: "flex",
    borderTop: "dt_divider",
    padding: "m",
    ":hover > button": {
      opacity: 1,
    },
  },
  closeButton: {
    position: "absolute" as const,
    top: 0,
    right: 0,
    padding: "m",
    backgroundColor: "dt_content_light",
    opacity: 0,
    ":focus, :focus-visible": { opacity: 1 },
  },
  commentTimeDeleteContainer: {
    textAlign: "right" as const,
    fontSize: "1.4rem",
    width: "45px",
    color: "dt_content_tertiary",
  },
};
