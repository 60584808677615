// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronLeftIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronLeftIcon: NessieFunctionComponent<ChevronLeftIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronLeftIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronLeftIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M6.20026 11.9844C6.19625 12.5014 6.39149 13.0197 6.78598 13.4142L13.7156 20.3439C14.4967 21.1249 15.763 21.1249 16.544 20.3439C17.3251 19.5628 17.3251 18.2965 16.544 17.5154L11.0132 11.9846L16.5441 6.45376C17.3251 5.67272 17.3251 4.40639 16.5441 3.62534C15.763 2.84429 14.4967 2.84429 13.7156 3.62534L6.78598 10.555C6.39159 10.9494 6.19635 11.4675 6.20026 11.9844Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronLeftIcon.nessiePropTypes = iconNessiePropTypes;
ChevronLeftIcon = nessiePropCheckerWrapper(ChevronLeftIcon);
export default ChevronLeftIcon;
