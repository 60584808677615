// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type HeartIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let HeartIcon: NessieFunctionComponent<HeartIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "HeartIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: HeartIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M13.3492 20.4822C12.5895 21.1743 11.4199 21.1743 10.6602 20.4721L10.5503 20.3718C5.3023 15.6076 1.87362 12.4882 2.00357 8.59653C2.06355 6.89141 2.93321 5.25651 4.34267 4.29362C6.98166 2.48821 10.2404 3.33073 11.9997 5.39693C13.759 3.33073 17.0178 2.47818 19.6568 4.29362C21.0662 5.25651 21.9359 6.89141 21.9959 8.59653C22.1358 12.4882 18.6972 15.6076 13.4492 20.3919L13.3492 20.4822Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
HeartIcon.nessiePropTypes = iconNessiePropTypes;
HeartIcon = nessiePropCheckerWrapper(HeartIcon);
export default HeartIcon;
