import { ReactNode } from "react";
import { ThemeUIStyleObject } from "../../stylingLib";
import { BadgeWithChildren, BadgeProps, BadgeSizesType } from "./Badge";

/**
 * xxs size, is too small to read so it is empty. in that case, use the Badge component without value
 */
export type NumberBadgeSizes = Exclude<BadgeSizesType, "xxs">;

export type NumberBadgeProps = BadgeProps & {
  /**
   * if you need a badge without value, please use the Badge component
   */
  value: number;
  max?: number;
  size?: NumberBadgeSizes;
};
/**
 * This is to avoid people adding children to the NumberBadge component
 * also in normal usage we want to make sure NumberBadge always has a value,
 * but in this specific case we need it to be optional
 */
type PropsWithChildrenAndValueOptional<T> = Omit<T, "children" | "value"> & { children?: ReactNode; value?: number };

export const NumberBadge = ({ size = "m", value, max, ...rest }: NumberBadgeProps): JSX.Element => {
  const valueToDisplay = value !== undefined && max && value > max ? `${max}+` : value;
  return (
    <BadgeWithChildren.Badge size={size} sx={fontSizeStyles[size]} {...rest}>
      {valueToDisplay}
      {rest.children}
    </BadgeWithChildren.Badge>
  );
};

type ComponentLibraryProps = {
  NumberBadge: ({ size, value, max, ...rest }: PropsWithChildrenAndValueOptional<NumberBadgeProps>) => JSX.Element;
};

// eslint-disable-next-line react-refresh/only-export-components
export const NumberBadgeForWeb: ComponentLibraryProps = {
  NumberBadge,
};

const fontSizeStyles: Record<NumberBadgeSizes, ThemeUIStyleObject> = {
  xs: {
    fontSize: "12px",
    lineHeight: "12px",
    padding: "dt_xxs",
  },
  s: {
    fontSize: "16px",
    lineHeight: "16px",
    padding: "dt_xs",
  },
  m: {
    fontSize: "22px",
    lineHeight: "22px",
  },
  l: {
    fontSize: "35px",
    lineHeight: "35px",
  },
  xl: {
    fontSize: "60px",
    lineHeight: "60px",
  },
};
