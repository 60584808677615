import * as logClient from "@classdojo/log-client";
import debounce from "lodash/debounce";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import * as React from "react";
import { T } from "../../index";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { handleActionKeyDown } from "../../utils/keyboard";
import { translate } from "../../utils/translate";
import { UnstyledButton } from "../buttons";
import { useImageSrc } from "./Image";

type VideoProps = {
  sources?: Array<{
    path: React.ComponentPropsWithoutRef<"source">["src"];
    contentType: React.ComponentPropsWithoutRef<"source">["type"];
  }>;
  onEnd?: React.ComponentPropsWithoutRef<"video">["onEnded"];
  hideControls?: boolean;
  containerStyle?: ThemeUIStyleObject;
  videoStyle?: ThemeUIStyleObject;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<React.ComponentPropsWithoutRef<"video">, "onClick" | "tabIndex" | "aria-label">;

const Video = ({
  src,
  sources,
  autoPlay,
  preload = "auto",
  onPlay,
  onPause,
  onEnd,
  onSeeking,
  onSeeked,
  onClick,
  hideControls,
  containerStyle = {},
  videoStyle = {},
  ...props
}: VideoProps): JSX.Element => {
  const [started, setStarted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);

  const playButtonSrc = useImageSrc("components/video/play_default.png");

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      setStarted(true);
      setPlaying(true);
      onPlay && onPlay(e);
    },
    [onPlay],
  );

  const handlePause = useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      setStarted(true);
      setPlaying(false);
      onPause && onPause(e);
    },
    [onPause],
  );

  const handleEnd = useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
      }
      setStarted(false);
      setPlaying(false);
      onEnd && onEnd(e);
    },
    [onEnd],
  );

  const handleSeeking = useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      setSeeking(true);
      onSeeking && onSeeking(e);
    },
    [onSeeking],
  );

  const handleSeeked = useMemo(
    () =>
      debounce((e: React.SyntheticEvent<HTMLVideoElement>) => {
        setSeeking(false);
        onSeeked && onSeeked(e);
      }, 300),
    [onSeeked],
  );

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [src, sources]);

  const play = () => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {
        logClient.sendMetrics([
          {
            type: "increment",
            value: 1,
            metricName: "web.media.video.play.error",
          },
        ]);
      });
    }
  };

  const pause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    playing ? pause() : play();
    onClick && onClick(e);
  };

  const handleKeyDown = handleActionKeyDown({ onAction: handleClick });

  const renderPlayButton = () => {
    if (playing || seeking || autoPlay) return;
    return (
      <UnstyledButton
        data-name="video:play_button"
        onClick={handleClick}
        aria-label={translate({ str: "dojo.common:portfolio.play_video", fallback: "Play video" })}
        onKeyDown={handleKeyDown}
        sx={{
          ...playButtonStyles,
          backgroundImage: `url(${playButtonSrc})`,
        }}
      ></UnstyledButton>
    );
  };

  return (
    <div data-name="videoComponent" sx={{ ...styles.container, ...containerStyle }}>
      <video
        ref={videoRef}
        {...props}
        src={src}
        autoPlay={autoPlay}
        preload={preload}
        sx={{ ...styles.video, ...videoStyle }}
        controls={started && !hideControls}
        onPlaying={handlePlay}
        onPause={handlePause}
        onEnded={handleEnd}
        onSeeking={handleSeeking}
        onSeeked={handleSeeked}
      >
        {sources && sources.map((source, i) => <source key={i} src={source.path} type={source.contentType} />)}
        <p>
          <T
            str="dojo.common:video.your_browser_does"
            fallback="Your browser does not support video. Please update your browser."
          />
        </p>
      </video>
      {renderPlayButton()}
    </div>
  );
};

export default Video;

const styles: Record<string, ThemeUIStyleObject> = {
  container: {
    position: "relative",
    backgroundColor: "dt_taro100",
    height: "100%",
  },
  video: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
};

const playButtonStyles: ThemeUIStyleObject = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "11.2rem",
  height: "11.2rem",
  backgroundSize: "cover",
};
