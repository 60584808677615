// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type HangerIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let HangerIcon: NessieFunctionComponent<HangerIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "HangerIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: HangerIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 20 15"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.42211 12.9546H17.5779C17.7986 12.9546 17.9775 12.7736 17.9775 12.5505C17.9775 12.4 17.8948 12.262 17.763 12.1923L11.4052 8.5052C10.5249 8.03993 9.47512 8.03993 8.59481 8.5052L2.23704 12.1923C2.10519 12.262 2.02254 12.4 2.02254 12.5505C2.02254 12.7736 2.20144 12.9546 2.42211 12.9546ZM1.30025 10.3795C0.501008 10.8019 0 11.6384 0 12.5505C0 13.9033 1.08442 15 2.42211 15H17.5779C18.9156 15 20 13.9033 20 12.5505C20 11.6384 19.499 10.8019 18.6998 10.3795L12.342 6.69239C10.8748 5.91693 9.1252 5.91693 7.65802 6.69239L1.30025 10.3795Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.83145 2.04545C9.27295 2.04545 8.82018 2.50334 8.82018 3.06817C8.82018 3.63301 8.36742 4.0909 7.80891 4.0909C7.25041 4.0909 6.79764 3.63301 6.79764 3.06817C6.79764 1.37367 8.15593 0 9.83145 0C11.507 0 12.8653 1.37367 12.8653 3.06817C12.8653 3.67579 12.59 4.15121 12.3318 4.48203C12.099 4.78028 11.8044 5.05313 11.5783 5.26263C11.5607 5.27895 11.5435 5.29489 11.5267 5.31043C11.2637 5.55472 11.0826 5.73059 10.9591 5.89858C10.8449 6.054 10.8427 6.1206 10.8427 6.13635C10.8427 6.70118 10.39 7.15907 9.83145 7.15907C9.27295 7.15907 8.82018 6.70118 8.82018 6.13635C8.82018 5.52709 9.07087 5.03888 9.33587 4.67834C9.59129 4.33082 9.91531 4.02991 10.1578 3.80471L10.1587 3.80385C10.4329 3.54919 10.6159 3.3791 10.7442 3.21472C10.8158 3.12299 10.8372 3.07353 10.8427 3.05729C10.8369 2.49747 10.3864 2.04545 9.83145 2.04545Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
HangerIcon.nessiePropTypes = iconNessiePropTypes;
HangerIcon = nessiePropCheckerWrapper(HangerIcon);
export default HangerIcon;
