export function handleActionKeyDown(handlers: Record<string, React.ReactEventHandler | undefined>) {
  return function (event: React.KeyboardEvent): void {
    const keyCode = _getKeyCode(event);
    if (event.target === event.currentTarget) {
      if (_isActionKey(keyCode) && typeof handlers.onAction === "function") {
        event.preventDefault();
        handlers.onAction(event);
      } else if (_isCancelKey(keyCode) && typeof handlers.onCancel === "function") {
        event.preventDefault();
        handlers.onCancel(event);
      }
    }
  };
}

function _getKeyCode(event: React.KeyboardEvent) {
  return event.key || event.keyCode || event.which;
}

const actionKeyCodes: Record<string | number, boolean> = { Enter: true, 13: true, " ": true, 32: true };
function _isActionKey(keyCode: string | number) {
  return !!actionKeyCodes[keyCode];
}

const cancelKeyCodes: Record<string | number, boolean> = { Escape: true, 27: true };
function _isCancelKey(keyCode: string | number) {
  return !!cancelKeyCodes[keyCode];
}
