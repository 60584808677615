// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ActivityIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ActivityIcon: NessieFunctionComponent<ActivityIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ActivityIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ActivityIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0422 1.00012C11.467 1.00012 10.9848 1.40556 10.8689 1.94678L10.7503 2.50012H9.70093C9.05265 2.50012 8.48916 2.9452 8.339 3.57585C8.12954 4.45558 8.79661 5.30012 9.70093 5.30012H14.3835C15.2878 5.30012 15.9549 4.45558 15.7455 3.57585C15.5953 2.9452 15.0318 2.50012 14.3835 2.50012H13.3341L13.2156 1.94678C13.0996 1.40556 12.6174 1.00012 12.0422 1.00012ZM7.27573 2.50012H6C4.89543 2.50012 4 3.39555 4 4.50012V19.5001C4 20.6047 4.89543 21.5001 6 21.5001H18C19.1046 21.5001 20 20.6047 20 19.5001V4.50012C20 3.39555 19.1046 2.50012 18 2.50012H16.8087C16.9416 2.73036 17.0433 2.98253 17.1074 3.25159C17.2093 3.6797 17.2074 4.10366 17.1202 4.50012H18V19.5001H6L6 4.50012H6.96424C6.877 4.10366 6.87514 3.6797 6.97707 3.25159C7.04114 2.98253 7.14281 2.73036 7.27573 2.50012ZM11.7365 14.5194C11.5322 14.7302 11.2522 14.8224 10.9816 14.7955C10.7403 14.7952 10.4993 14.6997 10.3201 14.5098L8.62894 12.7175C8.31732 12.3873 8.32484 11.8691 8.64591 11.548C8.96698 11.2269 9.48517 11.2194 9.81542 11.531L11.0076 12.656L14.7461 9.03349C15.0709 8.71871 15.5883 8.72279 15.9081 9.04265C16.228 9.36251 16.2321 9.87984 15.9173 10.2047L11.7365 14.5194Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ActivityIcon.nessiePropTypes = iconNessiePropTypes;
ActivityIcon = nessiePropCheckerWrapper(ActivityIcon);
export default ActivityIcon;
