import { type HighchartsReact } from "highcharts-react-official";
import { Suspense, lazy } from "react";
import LoadingMojo from "./LoadingMojo";
import type Highcharts from "highcharts";

export type { Highcharts, HighchartsReact };
const LazyHighchart = lazy(async () => {
  const [Highcharts, HighchartsReact, highchartsAccessibility] = await Promise.all([
    import("highcharts"),
    import("highcharts-react-official"),
    import("highcharts/modules/accessibility"),
  ]);

  if (typeof window !== "undefined") {
    highchartsAccessibility.default(Highcharts);
  }

  return { default: (props) => <HighchartsReact.default highcharts={Highcharts} {...props} /> };
});

type HighchartProps = Omit<HighchartsReact.Props, "highcharts">;

export const Highchart = (props: HighchartProps) => {
  return (
    <Suspense fallback={<LoadingMojo noOverlay={true} />}>
      <LazyHighchart {...props} />
    </Suspense>
  );
};
