import useWatch from "../../hooks/useWatch";
import isRetinaEnvironment from "is-retina";
import { type FC, type MutableRefObject, forwardRef, useState } from "react";
import type { SetRequired } from "type-fest";
import { ImportGlobFunction } from "vite/types/importGlob";

type WebpackRequireContext = {
  keys: () => string[];
  (id: string): string;
};

let imagesWebpack: WebpackRequireContext;
let imagesVite: ReturnType<ImportGlobFunction>;
let prefix: string;

// These ESLint / ts-ignores shouldn't be necessary, but I couldn't figure out how to
// get it to work in every environment that `web` is used in:
if (Config.bundler === "vite") {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  imagesVite = import.meta.glob<string>("../../assets/images/**/*.(png|gif|jpeg|jpg|svg|webp)", {
    query: "?url",
    import: "default",
    eager: true,
  });
  prefix = "../../assets/images";
} else {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  imagesWebpack = require.context("../../assets/images/", true, /.+\.(png|gif|jpe?g|svg|webp)$/);
  prefix = ".";
}

const TRANSPARENT_PNG_DATA_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

function fileExists(id: string): boolean {
  if (imagesVite) return id in imagesVite;
  if (imagesWebpack) return imagesWebpack.keys().includes(id);
  throw new Error("invalid useImageSrc builder - not webpack or vite?");
}

// eslint-disable-next-line react-refresh/only-export-components
export function useImageSrc(path?: string): string {
  const [imageSrc, setImageSrc] = useState<string>(TRANSPARENT_PNG_DATA_URL);

  const [, file, extension] = path?.match(/^(.*?)\.(\w{3,4})$/) || [];

  useWatch(file, () => {
    const plainPath = `${prefix}/${file}.${extension}`;
    const retinaPath = `${prefix}/${file}@2x.${extension}`;

    const shouldUseRetina = isRetinaEnvironment() && fileExists(retinaPath);
    const path = shouldUseRetina ? retinaPath : plainPath;

    if (!fileExists(path)) {
      setImageSrc(TRANSPARENT_PNG_DATA_URL);
      // eslint-disable-next-line no-console
      console.warn(`Couldn't locate image ${path}`);
      return;
    }

    if (imagesWebpack) setImageSrc(imagesWebpack(path));
    else if (imagesVite) {
      const m = imagesVite[path] as string;
      setImageSrc(m);
    } else {
      throw new Error("invalid useImageSrc builder - not Webpack or Vite?");
    }
  });

  return imageSrc;
}

type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
type Props = SetRequired<ImageProps, "src" | "alt">;

export const Image: FC<Props> = forwardRef(({ src, alt, ...rest }: Props, ref: MutableRefObject<HTMLImageElement>) => {
  const resolvedSrc = useImageSrc(src);

  if (!resolvedSrc) return <img {...rest} alt={alt} />;
  return <img ref={ref} src={resolvedSrc} {...rest} alt={alt} />;
});
